import Chat from "../../../../assets/Chat";
import Clients from "../../../../assets/Clients";
import Dashboard from "../../../../assets/Dashboard";
import Document from "../../../../assets/Document";
import Invoice from "../../../../assets/Invoice";
import Pipeline from "../../../../assets/Pipeline";
import Services from "../../../../assets/Services";
import Team from "../../../../assets/Team";

export const sidebarData = [
    {
        title: 'Dashboard',
        icon: <Dashboard />,
        url: '/dashboard',
        activeUrl: ['/dashboard']
    },
    {
        title: 'Services',
        icon: <Services />,
        url: '/services',
        activeUrl: ['/services']
    },
    // {
    //     title: 'Organizer',
    //     icon: <Services />,
    //     url: '/organizer',
    //     activeUrl: ['/organizer']
    // },
    {
        title: 'Documents',
        icon: <Document />,
        url: '/documents',
        activeUrl: ['/documents', '/documents_trash']
    },
    {
        title: 'Team',
        icon: <Team />,
        url: '/team',
        activeUrl: ['/team']
    },
    {
        title: 'Clients',
        icon: <Clients />,
        url: '/clients',
        activeUrl: ['/clients','/client_details']
    },
    {
        title: 'Pipeline',
        icon: <Pipeline />,
        url: '/pipeline',
        activeUrl: ['/pipeline', '/task', '/task_detail',]
    },
    {
        title: 'Invoice',
        icon: <Invoice />,
        url: '/invoice',
        activeUrl: ['/invoice']
    },
    {
        title: 'Chat',
        icon: <Chat />,
        url: '/chat',
        activeUrl: ['/chat']
    },
];