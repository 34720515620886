import React from 'react';
import ReactApexChart from 'react-apexcharts';

export default function AreaChart({ data, colors, height }) {
    if (!data || !Array.isArray(data.series) || !Array.isArray(data.categories)) {
        return null;
    }

    const options = {
        chart: {
            type: 'area',
            toolbar: {
                show: false
            },
            margin: 0,
            padding: 0,
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            categories: data?.categories,
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            labels: {
                show: false
            }
        },
        yaxis: {
            show: false
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                type: "vertical",
                opacityFrom: 0.7,
                opacityTo: 0,
                colorStops: [
                    {
                        offset: 0,
                        color: colors[0], // Start with your defined color
                        opacity: 0.8
                    },
                    {
                        offset: 100,
                        color: '#ffffff', // End with white
                        opacity: 0
                    }
                ]
            }
        },
        grid: {
            show: false
        },
        tooltip: { enabled: false },
        colors: colors,
        legend: {
            show: false
        }
    }

    return (
        <div>
            <ReactApexChart options={options} series={data?.series} type="area" height={height || 285} />
        </div>
    )
}