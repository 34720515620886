import React, { useEffect, useState } from 'react'
import Add from '../../assets/Add'
import SkeletonTable from '../../components/table/SkeletonTable'
import columns from '../../columns/pipeline/column'
import { Dropdown, Form, Input } from 'antd'
import ModalComponent from '../../components/global/modals/ModalComponent'
import LoadableButton from '../../components/button/LoadableButton'
import { useNavigate } from 'react-router-dom'

const Pipeline = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isPipeline, setIsPipeline] = useState(false);
    const [isPipelineLoading, setIsPipelineLoading] = useState(false);

    const [createPipelineForm] = Form.useForm();

    const pipelineFormValue = { file_name: '' };

    const tableData = [
        { file: 'Business' },
        { file: 'Incorporation' },
        { file: 'Tax file' },
    ];
    const items = [
        {
            label: <div className='flex items-center cursor-pointer gap-3'><img src='/assets/icons/arrows-pointing-out.svg' className='size-4 rotate-45' alt='icon' />
                <p>Move</p></div>,
            key: '0',
        },
        {
            label: <div className='flex items-center cursor-pointer gap-3'><img src='/assets/icons/delete.svg' className='size-4' alt='icon' /><p>Delete</p></div>,
            key: '1',
        },
    ];
    useEffect(() => {
        setData(tableData.map((item) => {
            return {
                ...item,
                file: <div className='flex items-center space-x-2 cursor-pointer hover:underline hover:text-primary' onClick={() => navigate('/task')}>
                    <span>{item.file}</span></div>,
                action: (
                    <Dropdown menu={{ items }} trigger={['click']} className='cursor-pointer size-6 min-h-6 min-w-6 '>
                        <img src='/assets/icons/ellipsis-vertical.svg' alt='icon' />
                    </Dropdown>
                )
            }
        }));
    }, [])
    return (
        <>
            <div>
                <div className='border rounded-lg bg-White'>
                    <div className='py-5 px-4 flex items-center justify-between'>
                        <h2 className='font-semibold text-xl'>Pipeline</h2>
                        <button onClick={() => setIsPipeline(true)} className='bg-secondary flex items-center gap-2 rounded-lg text-White py-2 px-3'>
                            <Add /><span>New Pipeline</span>
                        </button>
                    </div>
                    <div className='px-4'>
                        <SkeletonTable columns={columns} data={data} isLoading={false} />
                    </div>
                </div>
                <ModalComponent isOpen={isPipeline} setIsOpen={setIsPipeline} title='Create Pipeline'>
                    <Form className='w-full' autoComplete="off" form={createPipelineForm} initialValues={pipelineFormValue}>
                        <div className='input-white'>
                            <Form.Item name="file_name" className='mb-0' rules={[{ required: true, message: 'Please enter folder name.' }]}>
                                <Input placeholder="Folder name" className="mt-1 w-full ps-4 py-2" />
                            </Form.Item>
                        </div>
                        <div className='text-center mt-2'>
                            <LoadableButton
                                className='bg-primary text-sm text-white font-medium px-8 py-2 mt-6 rounded-lg themeHover duration-500'
                                type="submit"
                                lable='Cteate'
                                isLoading={isPipelineLoading}
                                loadingLable='Creating pipeline...'
                            />
                        </div>
                    </Form>
                </ModalComponent>
            </div>
        
        </>
    )
}

export default Pipeline