import React, { useState } from 'react';
import { motion } from "framer-motion";
import Header from "../header/Header.jsx";
import Sidebar from '../sidebar/Sidebar.jsx';

export default function AppLayout({ children, title = "TrackMyDoc" }) {

  const [collapsed, setCollapsed] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <div>
      <div className='flex'>
        <motion.div className='hidden lg:block' animate={{ marginLeft: collapsed ? "0px" : "-250px", transition: { duration: 0.5 } }}>
         <Sidebar/>
        </motion.div>
        <div className={`bg-bgColor lg:ml-[250px] ${collapsed ? 'lg:w-[calc(100%-250px)]' : 'lg:w-full'} w-full `} >
          <Header collapsed={collapsed} title={title} setCollapsed={setCollapsed} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
          <div className='p-2 md:p-4 h-screen'>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}