import React, { useState } from 'react';
import Section from './Section';
import Questions from './Questions';

export default function File() {

  const [selectedSection, setSelectedSection] = useState(null);

  return (
    <div>
      <div className='grid xl:grid-cols-4 gap-4'>
        <Section selectedSection={selectedSection} setSelectedSection={setSelectedSection}></Section>

        <div className='xl:col-span-3'>
          <Questions selectedSection={selectedSection} setSelectedSection={setSelectedSection}></Questions>
        </div>
      </div>
    </div >
  )
}