import React from 'react';

const Dashboard = ({ active }) => {
    const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.20828 0H1.95828C1.15414 0 0.5 0.654141 0.5 1.45828V5.20828C0.5 6.01258 1.15414 6.66672 1.95828 6.66672H8.20828C9.01258 6.66672 9.66672 6.01258 9.66672 5.20828V1.45828C9.66672 0.654141 9.01258 0 8.20828 0ZM8.20828 8.33328H1.95828C1.15414 8.33328 0.5 8.98742 0.5 9.79172V18.5417C0.5 19.3459 1.15414 20 1.95828 20H8.20828C9.01258 20 9.66672 19.3459 9.66672 18.5417V9.79172C9.66672 8.98742 9.01258 8.33328 8.20828 8.33328ZM19.0417 13.3333H12.7917C11.9874 13.3333 11.3333 13.9874 11.3333 14.7917V18.5417C11.3333 19.3459 11.9874 20 12.7917 20H19.0417C19.8459 20 20.5 19.3459 20.5 18.5417V14.7917C20.5 13.9874 19.8459 13.3333 19.0417 13.3333ZM19.0417 0H12.7917C11.9874 0 11.3333 0.654141 11.3333 1.45828V10.2083C11.3333 11.0126 11.9874 11.6667 12.7917 11.6667H19.0417C19.8459 11.6667 20.5 11.0126 20.5 10.2083V1.45828C20.5 0.654141 19.8459 0 19.0417 0Z" fill={`${active ? 'white' : 'black'}`} stroke-width="2"  />
        </svg>

    )
}

export default Dashboard