import React from 'react';
import { Form, Input } from 'antd'
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ForgotPasswordLinkApi } from '../../api/request/auth';
import toast from 'react-hot-toast';
import LoadableButton from '../../components/button/LoadableButton';

const Forgot = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [initialValues, setInitialValues] = useState({ email: '' });
    const [isSuccess, setIsSuccess] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);
            const { data } = await ForgotPasswordLinkApi(values);
            if (data?.status) {
                setIsSuccess(false);
                form.resetFields();
                toast.success(data?.message);
            }
            setIsLoading(false);
        }
        catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoading(false);
        }

    }
    return (
        <>
            <div className='lg:w-3/5 md:w-[80%] w-full sm:p-0 p-4'>
                {isSuccess ?
                    <Form layout='vertical' autoComplete='off' form={form} onFinish={handleSubmit} initialValues={initialValues} className='space-y-7'>
                        <div className='text-center'>
                            <p className='font-bold sm:text-3xl text-2xl mb-3'>Forgot your password ?</p>
                            <p className="text-sm text-Gray">Don’t worry, happens to all of us. Enter your email below to recover your password</p>
                        </div>
                        <Form.Item
                            label={<p className='font-semibold'>Email</p>}
                            name="email"
                            rules={[
                                { required: true, message: 'Please input your email!', },
                                { type: 'email', message: 'Please input valid email!', },
                            ]}
                        >
                            <Input placeholder='Email address' size='large' />
                        </Form.Item>
                        <LoadableButton
                            isLoading={isLoading}
                            type='submit'
                            lable='Submit'
                            img='assets/icons/ArrowRight.svg'
                            className='bg-secondary text-white text-base w-full py-2 rounded-md mb-5 flex justify-center items-center'
                        />
                        <div className='text-primary flex items-center justify-center mb-5'><img src="assets/icons/ArrowLeft.svg" alt="icon" className='me-3' />
                            <Link to='/login'>Back to login</Link>
                        </div>
                    </Form> :
                    <div className='text-primary font-semibold text-2xl'>Please check your mail to reset password!</div>
                }
            </div>
        </>
    )
}

export default Forgot