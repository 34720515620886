const defaultRender = (value) => (value ? value : '--');

const columns = [
    {
        title: 'File',
        dataIndex: 'file',
        render: defaultRender,
    },
    {
        title: 'Uoloaded Date',
        dataIndex: 'uploadedDate',
        render: defaultRender,
    },
    {
        title: 'Action',
        dataIndex: 'actions',
        render: defaultRender,
    },
];

export default columns;