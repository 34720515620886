import React from 'react'

const Logout = () => {
    return (
        <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8234 21.3874V22.6141C10.8234 23.5979 10.3331 24.4472 9.48108 24.939C9.07117 25.1757 8.61366 25.3077 8.13932 25.3079C7.66456 25.3082 7.20719 25.1759 6.79699 24.939L1.34228 21.7897C0.490201 21.2978 0 20.4488 0 19.4649V3.37664C0 1.89628 1.20425 0.692078 2.68457 0.692078H14.6978C16.1782 0.692078 17.3826 1.89618 17.3826 3.37664V6.768C17.3826 7.2545 16.9876 7.6495 16.5011 7.6495C16.0144 7.6495 15.6197 7.25455 15.6197 6.768V3.37664C15.6197 2.86832 15.2061 2.4547 14.6978 2.4547H4.17108L9.48108 5.52096C10.3328 6.01279 10.8234 6.86185 10.8234 7.84537V19.6246H14.6978C15.2061 19.6246 15.6197 19.2112 15.6197 18.7028V15.7299C15.6197 15.243 16.0142 14.8484 16.5011 14.8484C16.9877 14.8484 17.3826 15.2431 17.3826 15.7299V18.7028C17.3826 20.1833 16.1782 21.3874 14.6978 21.3874H10.8234ZM18.991 11.921L18.0202 12.8918C17.676 13.2359 17.6761 13.7939 18.0202 14.1382C18.102 14.2201 18.1992 14.285 18.3061 14.3293C18.4131 14.3735 18.5277 14.3962 18.6434 14.3961C18.7592 14.3963 18.8739 14.3736 18.9809 14.3294C19.0879 14.2851 19.1851 14.2201 19.2669 14.1382L21.7419 11.6627C22.086 11.3186 22.0861 10.7609 21.7419 10.4168L19.2669 7.94172C18.9227 7.59748 18.3646 7.59763 18.0203 7.94167C17.6762 8.28553 17.6763 8.84376 18.0203 9.1877L18.991 10.1582H12.488C12.001 10.1582 11.6066 10.5528 11.6066 11.0398C11.6066 11.5267 12.001 11.9211 12.488 11.9211H18.991V11.921Z" fill="white" />
        </svg>

    )
}

export default Logout