const defaultRender = (value) => (value ? value : '--');

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        render: defaultRender,
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        render: defaultRender,
    },
    {
        title: 'Charges',
        dataIndex: 'charges',
        render: defaultRender,
    },
    {
        title: 'Action',
        dataIndex: 'action',
        render: defaultRender,
    },
];

export default columns;