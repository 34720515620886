import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import Add from '../../../assets/Add';
import ModalComponent from '../../../components/global/modals/ModalComponent.jsx';
import { Form, Input, Spin } from 'antd';
import LoadableButton from '../../../components/button/LoadableButton.jsx';
import { AddSectionApi, GetAllSectionsApi, ReOrderSectionApi } from '../../../api/request/organizer.js';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const Section = ({ selectedSection, setSelectedSection }) => {

    const [form] = Form.useForm();
    const { organizerTypeId } = useParams();
    const initialValues = { section_name: "" }
    const [sections, setSections] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sectionLoading, setSectionLoading] = useState(false);

    const handleOnDragEnd = async (result) => {
        if (!result.destination) return;
        const items = Array.from(sections);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setSections(items);
        try {
            let params = {
                section_id: result?.draggableId,
                section_order: result?.destination?.index + 1
            };
            const { data } = await ReOrderSectionApi(params);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
        }
    };

    const addSection = async (value) => {
        setIsLoading(true)
        try {
            const { data } = await AddSectionApi({ ...value, organizer_type_id: organizerTypeId })
            if (data.status) {
                setIsLoading(false);
                setIsOpen(false);
                getSections();
            }
        } catch (err) { }
    }

    const getSections = async () => {
        try {
            setSectionLoading(true)
            const { data } = await GetAllSectionsApi({ organizer_type_id: organizerTypeId })
            if (data.status) {
                setSections(data?.data)
            }
        } catch (err) { }
        setSectionLoading(false)
    }

    useEffect(() => {
        if (selectedSection == null) {
            getSections();
        }
        setSections(prevSection => prevSection.map((item) => item.id === selectedSection?.id ? selectedSection : item));
    }, [selectedSection]);

    useEffect(() => {
        form.resetFields();
    }, [isOpen]);

    return (
        <div className='border-2 rounded-lg'>
            <div className='flex items-center justify-between border-b-2 p-3 bg-secondaryLight'>
                <p className='font-semibold uppercase'>New Section</p>
                <div onClick={e => setIsOpen(true)}>
                    <Add></Add>
                </div>
            </div>
            <div className='sidebar-container px-3 py-8 h-full'>
                {
                    sectionLoading ? <div className='flex justify-center items-center h-full'><Spin /></div> :
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="sections">
                                {(provided) => (
                                    <div
                                        className="section-list"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {sections.map((section, index) => (
                                            <Draggable key={section.id} draggableId={section.id} index={index}>
                                                {(provided) => (
                                                    <div
                                                        className={`section-item section-shadow mb-6 flex items-center font-semibold rounded-md duration-500 ${section.id == selectedSection?.id ? 'text-primary' : ''}`}
                                                        onClick={() => setSelectedSection(section)}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <img src='/assets/icons/double-ellipsis-vertical.svg' className='cursor-grab' />
                                                        <p className='line-clamp-1 break-words'>{section.section_name}</p>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                }
            </div>
            <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} title={"Add New Section"}>
                <Form autoComplete='off' form={form} initialValues={initialValues} onFinish={addSection}>
                    <Form.Item name="section_name" rules={[{ required: true, message: 'Please enter organizername!', },]}>
                        <Input placeholder='Enter section name...' size='large' />
                    </Form.Item>
                    <div className='flex justify-center '>
                        <LoadableButton
                            className='bg-primary text-white uppercase text-base font-semibold w-full py-2 rounded-lg mt-2'
                            type='submit'
                            lable='Submit'
                            loadingLable='Adding Section...'
                            isLoading={isLoading}
                        />
                    </div>
                </Form>
            </ModalComponent>
        </div>
    )
}

export default Section