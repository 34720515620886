import React, { useState } from 'react';
import Add from '../../../assets/Add';
import { Form, Input, Select } from 'antd';
import LoadableButton from '../../../components/button/LoadableButton';
import ModalComponent from '../../../components/global/modals/ModalComponent';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


const { TextArea } = Input;
const { Option } = Select;

const Task = () => {
    const [addTaskForm] = Form.useForm();
    const [addTagForm] = Form.useForm();
    const [addSubTaskForm] = Form.useForm();
    const addTaskFormValue = { file_name: "" }
    const addTagFormValue = { tag_name: "" }
    const addSubTaskFormValue = { sub_task: "" }

    const [isTaskLoading, setIsTaskLoading] = useState(false);
    const [isTag, setIsTag] = useState(false);
    const [isTagLoading, setIsTagLoading] = useState(false);
    const [isSubTaskLoading, setIsSubTaskLoading] = useState(false);
    const [isSubTask, setIsSubTask] = useState(false);
    const [newSubTask, setNewSubTask] = useState('');

    const [items, setItems] = useState([
        { id: '1', content: 'Things to do' },
        { id: '2', content: 'Buy groceries' },
        { id: '3', content: 'Finish project' },
    ]);
    const [buttonData, setButtonData] = useState([
        { id: 1, label: 'Upload documents' },
        { id: 2, label: 'Upload folder' },
        { id: 3, label: 'New chat' },
        { id: 4, label: 'Book appointment' },
        { id: 5, label: 'Make prepayment' },
    ]);

    const handleAddTag = (values) => {
        setIsTagLoading(true);
        const newTag = {
            id: buttonData.length + 1,
            label: values.tag_name,
        };
        setButtonData([...buttonData, newTag]);
        addTagForm.resetFields();
        setTimeout(() => {
            setIsTagLoading(false);
            setIsTag(false);
        }, 1000);
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const newItems = Array.from(items);
        const [movedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, movedItem);
        setItems(newItems);
    };
    const handleDelete = (id) => {
        setItems(items.filter((item) => item.id !== id));
    };

    const handleAddSubTask = (values) => {
        setIsSubTaskLoading(true);
        const newTaskId = (items.length + 1).toString();
        setItems([...items, { id: newTaskId, content: values.sub_task }]);
        addSubTaskForm.resetFields();
        setTimeout(() => {
            setIsSubTask(false);
            setIsSubTaskLoading(false);
        }, 1000)
    };

    return (
        <>
            {/* <div className='flex flex-col items-center justify-center h-full'>
                <img src="/assets/images/task.png" alt="" className='mb-3' />
                <h2 className='font-semibold text-2xl mb-3'>You have no task</h2>
                <p className='text-Gray w-1/3 mb-3'>Using this software, you can ask yourquestions and receive articles using artificial intelligence assistant</p>
                <button className='bg-secondary flex items-center gap-2 rounded-lg text-White py-2 px-7 '>
                    <Add /><span>Add Task</span>
                </button>
            </div> */}

            <div className='grid sm:grid-cols-4 gap-3'>
                <div className='sm:col-span-3 border rounded-xl bg-White w-full'>
                    <div className='border-b'>
                        <h2 className='font-semibold text-xl px-4 py-4'>Demo</h2>
                    </div>
                    <Form className='w-full p-4' autoComplete="off" form={addTaskForm} initialValues={addTaskFormValue}>
                        <div>
                            <label className='text-base font-medium'>Name</label>
                            <Form.Item name="name" rules={[{ required: true, message: 'Please enter name.' }]} className='mb-2'>
                                <Input placeholder="name" className="mt-1 w-full ps-4 py-2" />
                            </Form.Item>
                        </div>
                        <Form.Item name="content" rules={[{ required: true, message: 'Please enter some content!' }]} className='mb-2'>
                            <TextArea placeholder="Add text or multimedia" autoSize={{ minRows: 3 }} />
                        </Form.Item>
                        <div>
                            <label className='text-base font-medium'>Select time duration</label>
                            <Form.Item name="time_duration" rules={[{ required: true, message: 'Please select time duration' }]} className='mt-2 mb-2'>
                                <Select placeholder='Select time duration' size='large'>
                                    <Option>Hour</Option>
                                    <Option>Day</Option>
                                    <Option>Month</Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div>
                            <label className='text-base font-medium'>Tag</label>
                            <div className='flex flex-wrap sm:flex-row flex-col gap-2 mt-2'>
                                {buttonData.map((item, index) => (
                                    <button key={index} type="button" className='border rounded-md px-3 py-2 border-Gray text-Gray' > {item.label} </button>
                                ))}
                            </div>
                        </div>
                        <button type='button' onClick={() => setIsTag(true)} className='border border-secondary text-secondary rounded-md flex items-center gap-2 px-2 py-0.5 mt-3'>
                            <Add color='#1E4387' /><span>Add Tags</span>
                        </button>

                        <LoadableButton
                            className='bg-secondary text-sm text-white font-medium px-8 py-2 mt-6 rounded-lg themeHover duration-500'
                            type="submit"
                            lable='Submit'
                            isLoading={isTaskLoading}
                            loadingLable='sumiting task...'
                        />
                    </Form>
                </div>
                <div className='pb-2'>
                    <div className='border rounded-xl bg-White p-5 '>
                        <p className='mb-5'>Sub Task</p>
                        <div>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {items.map((item, index) => (
                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                    {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                            className="flex items-center gap-2 mb-2 "
                                                        >
                                                            <img src="/assets/icons/move-dnd.svg" alt="icon" />
                                                            <div className='border rounded-md px-3 py-2 w-full'>{item.content}</div>
                                                            <button onClick={() => handleDelete(item.id)}><img src="/assets/icons/delete.svg" alt="icon" /></button>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        <button onClick={() => setIsSubTask(true)} className=' text-secondary rounded-md flex items-center gap-2 px-2 py-0.5 mt-5'>
                            <Add color='#1E4387' /><span>Add Sub Task</span>
                        </button>
                    </div>
                </div>
                <ModalComponent isOpen={isTag} setIsOpen={setIsTag} title='Add Tag'>
                    <Form className='w-full' autoComplete="off" form={addTagForm} initialValues={addTagFormValue} onFinish={handleAddTag}>
                        <div>
                            <Form.Item name="tag_name" className='mb-2' rules={[{ required: true, message: 'Please enter tag name.' }]}>
                                <Input placeholder="tag name" className="mt-1 w-full ps-4 py-2" />
                            </Form.Item>
                        </div>
                        <div className='text-center mt-2'>
                            <LoadableButton
                                className='bg-primary text-sm text-white font-medium px-8 py-2 mt-6 rounded-lg themeHover duration-500'
                                type="submit"
                                lable='Save'
                                isLoading={isTagLoading}
                                loadingLable='Adding tag...'
                            />
                        </div>
                    </Form>
                </ModalComponent>
                <ModalComponent isOpen={isSubTask} setIsOpen={setIsSubTask} title='Add Sub Task'>
                    <Form className='w-full' autoComplete="off" form={addSubTaskForm} initialValues={addSubTaskFormValue} onFinish={handleAddSubTask}>
                        <div>
                            <Form.Item name="sub_task" className='mb-2' rules={[{ required: true, message: 'Please enter sub task name.' }]}>
                                <Input placeholder="sub task" className="mt-1 w-full ps-4 py-2" />
                            </Form.Item>
                        </div>
                        <div className='text-center mt-2'>
                            <LoadableButton
                                className='bg-primary text-sm text-white font-medium px-8 py-2 mt-6 rounded-lg themeHover duration-500'
                                type="submit"
                                lable='Save'
                                isLoading={isSubTaskLoading}
                                loadingLable='Adding SubTask...'
                            />
                        </div>
                    </Form>
                </ModalComponent>
            </div>
        </>
    )
}

export default Task