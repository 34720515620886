import React, { useEffect, useState } from 'react';
import View from '../../assets/View';
import SkeletonTable from '../../components/table/SkeletonTable';
import columns from '../../columns/invoice/column';
import { Form, Input } from 'antd';
import Add from '../../assets/Add';
import ModalComponent from '../../components/global/modals/ModalComponent';
import LoadableButton from '../../components/button/LoadableButton';

const Invoice = () => {
    const [addInvoiceForm] = Form.useForm();

    const [isAdd, setIsAdd] = useState(false);
    const [isAddLoading, setIsAddLoading] = useState(false);
    const [data, setData] = useState([]);

    const invoiceFormValue = { name: '', amount: '', charges: '' };

    const tableData = [
        { name: 'John Doe', amount: 100, charges: 10 },
        { name: 'Jane Smith', amount: 50, charges: 5 },
        { name: 'Alex Johnson', amount: 60, charges: 6 },
    ];
    useEffect(() => {
        setData(tableData.map((item) => {
            return {
                ...item,
                amount: <p>$ {item.amount}</p>,
                charges: <p>$ {item.charges}</p>,
                action: <button> <View /> </button>
            }
        }));
    }, []);
    return (
        <div>
            <div className='border rounded-lg mt-5 bg-White'>
                <div className='py-5 px-4 flex items-center justify-between'>
                    <p className='font-semibold text-xl'>Invoice</p>
                    <div className='flex gap-2 items-center'>
                        <button onClick={() => setIsAdd(true)} className='bg-secondary flex items-center gap-2 rounded-lg text-White py-2 px-3'>
                            <Add /><span>Add</span>
                        </button>
                        <Input size="large" placeholder="Search here..." className='xl:w-80' prefix={
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#B3B3B3" class="size-6">
                                <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z" clip-rule="evenodd" />
                            </svg>}
                        />
                    </div>
                </div>
                <div className='mt-5'>
                    <SkeletonTable columns={columns} data={data} />
                </div>
            </div>
            <ModalComponent isOpen={isAdd} setIsOpen={setIsAdd} title='Create Invoice'>
                <Form className='w-full space-y-2' autoComplete="off" form={addInvoiceForm} initialValues={invoiceFormValue} >
                    <Form.Item name="name" className='mb-0' rules={[{ required: true, message: 'Please enter name.' }]}>
                        <Input placeholder="Name" className="mt-1 w-full ps-4 py-2" />
                    </Form.Item>
                    <Form.Item name="amount" className='mb-0' rules={[{ required: true, message: 'Please enter amount.' }]}>
                        <Input  placeholder="Amount" className="mt-1 w-full ps-4 py-2" />
                    </Form.Item>
                    <Form.Item name="charges" className='mb-0' rules={[{ required: true, message: 'Please enter charges.' }]}>
                        <Input placeholder="Charges" className="mt-1 w-full ps-4 py-2" />
                    </Form.Item>
                    <div className='text-center mt-2'>
                        <LoadableButton
                            className='bg-secondary text-sm text-White font-medium px-8 py-2 mt-6 rounded-lg themeHover duration-500'
                            type="submit"
                            lable='Cteate'
                            isLoading={isAddLoading}
                            loadingLable='Creating invoice...'
                        />
                    </div>
                </Form>
            </ModalComponent>
        </div>
    )
}

export default Invoice;