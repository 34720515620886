import React, { useEffect, useState } from 'react';
import SkeletonTable from '../../components/table/SkeletonTable';
import columns from '../../columns/services/column';
import { Form, Input } from 'antd';
import ModalComponent from '../../components/global/modals/ModalComponent';
import LoadableButton from '../../components/button/LoadableButton';
import Edit from '../../assets/Edit';
import { useNavigate } from 'react-router-dom';
import View from '../../assets/View';

const Services = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const initialValues = { serviceName: "" };
    const [data, setData] = useState([]);
    const tableData = [
        { id: 1, file: 'Document.pdf', uploadedDate: '24 may, 2024', },
        { id: 2, file: 'Presentation.pptx', uploadedDate: '24 may, 2024', },
        { id: 3, file: 'Image.png', uploadedDate: '24 may, 2024', },
        { id: 4, file: 'Spreadsheet.xlsx', uploadedDate: '24 may, 2024', },
        { id: 5, file: 'Report.docx', uploadedDate: '24 may, 2024', },
    ];

    useEffect(() => {
        setData(tableData.map((item) => {
            return {
                ...item,
                file:<p className='hover:text-primary'>{item.file}</p>,
                actions: <button onClick={()=>navigate('/file')}><View /></button>
            }
        }))
    }, []);

    return (
        <div className='border bg-White rounded-xl py-5'>
            <div className='flex items-center justify-between py-4 px-6 mb-2'>
                <h2 className='font-semibold text-xl'>Services</h2>
                <div>
                    <button className='ms-auto m-2 px-4 py-2 bg-primary text-white rounded-md' onClick={e => setIsOpen(true)}>Create Service</button>
                </div>
            </div>
            <div>
                <SkeletonTable columns={columns} data={data} />
            </div>

            <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} title={"Enter service name"}>
                <Form autoComplete='off' form={form} initialValues={initialValues}>
                    <Form.Item name="service_name" rules={[{ required: true, message: 'Please enter service name!', },]}>
                        <Input placeholder='Enter service name...' size='large' />
                    </Form.Item>
                    <div className='flex justify-center '>
                        <LoadableButton
                            className='bg-primary text-White uppercase text-base font-semibold w-full py-2 rounded-lg mt-2'
                            type='submit'
                            lable='Submit'
                            loadingLable='Creating Service...'
                            isLoading={isLoading}
                        />
                    </div>
                </Form>
            </ModalComponent>
        </div>
    )
}

export default Services