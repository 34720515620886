import api from '../index'

export const CreateOrganizerApi = (data) => api.post('addupdate-organizer-type', data);
export const CreateServiceType = (data) => api.post('addupdateservicetype', data);
export const OrganizerTypeListApi = () => api.post('organizer-typelist');

export const AddSectionApi = (data) => api.post('addupdate-section', data);
export const GetAllSectionsApi = (id) => api.post('organizer-section-list', id);
export const DeleteSectionApi = (data) => api.post('delete-organizer-cascade', data);
export const ReOrderSectionApi = (data) => api.post('reorder-organizer-section', data);

export const AddUpdateQuestionApi = (data) => api.post('addupdate-question', data);
export const QuestionListApi = (data) => api.post('org-section-wise-question-list', data);
export const DeleteQuestionApi = (data) => api.post('delete-organizer-cascade', data);
export const ReOrderQuestionApi = (data) => api.post('reorder-section-questions', data);

export const GetConditionalQuestionsApi = (data) => api.post('condition-que-list', data);

export const GetCellsDataApi = (data) => api.post('forminformationlist', data);