import React from 'react'

const Services = ({ active }) => {
    const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="white" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.1211 5.7793L20.0622 4.56758L17.4324 1.93785L16.2207 2.87891C15.2539 2.26011 14.1711 1.80898 13.0497 1.56406L12.8563 0H9.14375L8.95035 1.56406C7.82886 1.80898 6.74609 2.26011 5.7793 2.87891L4.56758 1.93785L1.93785 4.56758L2.87891 5.7793C2.26011 6.74609 1.80898 7.82886 1.56406 8.95035L0 9.14375V12.8563L1.56406 13.0496C1.80898 14.1711 2.26011 15.2539 2.87891 16.2207L1.93785 17.4324L4.56758 20.0621L5.7793 19.1211C6.74609 19.7398 7.82886 20.1911 8.95035 20.436L9.14375 22H12.8563L13.0497 20.4359C14.1711 20.191 15.2539 19.7398 16.2207 19.1211L17.4324 20.0621L20.0622 17.4324L19.1211 16.2207C19.7399 15.2539 20.1911 14.1711 20.436 13.0496L22 12.8563V9.14375L20.4359 8.95035C20.191 7.82886 19.7399 6.74609 19.1211 5.7793ZM11 18.0898C7.0941 18.0898 3.91016 14.9058 3.91016 11C3.91016 7.0941 7.0941 3.91016 11 3.91016C14.9059 3.91016 18.0898 7.0941 18.0898 11C18.0898 14.9058 14.9059 18.0898 11 18.0898Z"  fill={`${active ? 'white' : 'black'}`} stroke-width="2" />
            <path d="M11 5.19922C7.80317 5.19922 5.19922 7.80308 5.19922 11C5.19922 14.1968 7.80317 16.8008 11 16.8008C14.1968 16.8008 16.8008 14.1968 16.8008 11C16.8008 7.80308 14.1968 5.19922 11 5.19922ZM10.3555 13.2043L7.97075 10.8066L8.87305 9.90425L10.3555 11.3738L13.127 8.61519L14.0293 9.51753L10.3555 13.2043Z"
                fill={`${active ? 'white' : 'black'}`} stroke-width="2" />
        </svg>
    )
}

export default Services