import React from 'react';

const View = ({active}) => {
    const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();
    return (
        <svg width="27" height="15" viewBox="0 0 27 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.54052 5.09593C3.64678 6.87951 2.42603 9.02578 1.8926 10.422C1.80024 10.6637 1.61534 10.8589 1.37858 10.9647C1.14182 11.0705 0.872584 11.0782 0.630107 10.9862C0.387629 10.8941 0.191771 10.7098 0.085619 10.4738C-0.020533 10.2378 -0.0282834 9.96946 0.0640725 9.72778C0.690101 8.0898 2.06606 5.68613 4.19717 3.67895C6.33871 1.66008 9.29539 0 13.0424 0C16.7895 0 19.7448 1.66008 21.8877 3.67895C24.0188 5.68613 25.3948 8.0898 26.0208 9.72778C26.1131 9.96946 26.1054 10.2378 25.9992 10.4738C25.8931 10.7098 25.6972 10.8941 25.4547 10.9862C25.2123 11.0782 24.943 11.0705 24.7063 10.9647C24.4695 10.8589 24.2846 10.6637 24.1923 10.422C23.6575 9.02578 22.4368 6.87951 20.543 5.09593C18.661 3.32276 16.1647 1.94998 13.0424 1.94998C9.92011 1.94998 7.42121 3.32276 5.54052 5.09593ZM13.0424 7.14991C12.6571 7.14991 12.2755 7.22557 11.9194 7.37256C11.5634 7.51955 11.2399 7.735 10.9674 8.00661C10.6949 8.27822 10.4788 8.60066 10.3313 8.95554C10.1838 9.31041 10.1079 9.69076 10.1079 10.0749C10.1079 10.459 10.1838 10.8393 10.3313 11.1942C10.4788 11.5491 10.6949 11.8715 10.9674 12.1431C11.2399 12.4147 11.5634 12.6302 11.9194 12.7772C12.2755 12.9242 12.6571 12.9998 13.0424 12.9998C13.8207 12.9998 14.5671 12.6917 15.1174 12.1431C15.6678 11.5946 15.9769 10.8506 15.9769 10.0749C15.9769 9.29912 15.6678 8.55515 15.1174 8.00661C14.5671 7.45807 13.8207 7.14991 13.0424 7.14991ZM8.15158 10.0749C8.15158 8.78196 8.66686 7.542 9.58408 6.62777C10.5013 5.71354 11.7453 5.19993 13.0424 5.19993C14.3396 5.19993 15.5836 5.71354 16.5008 6.62777C17.418 7.542 17.9333 8.78196 17.9333 10.0749C17.9333 11.3678 17.418 12.6077 16.5008 13.522C15.5836 14.4362 14.3396 14.9498 13.0424 14.9498C11.7453 14.9498 10.5013 14.4362 9.58408 13.522C8.66686 12.6077 8.15158 11.3678 8.15158 10.0749Z" fill="#191B1C"
            className={`hover:fill-${primaryColor}`}/>
        </svg>
    )
}

export default View