import React, { useEffect, useState } from 'react';
import { GetClientDocumentsApi, DownloadFileFolderApi, GetChildFolderListApi, UploadFileFolderApi, DeleteFileFolderApi, MoveFileFolderApi } from '../../../api/request/clients/clients.js';
import SkeletonTable from '../../../components/table/SkeletonTable.jsx';
import columns from '../../../columns/clients/documents.js';
import { Breadcrumb, Button, Dropdown, Form, Image, Input, Upload } from 'antd';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import ModalComponent from '../../../components/global/modals/ModalComponent.jsx';
import ConfirmationModal from '../../../components/global/modals/ConfirmationModal.jsx';
import LoadableButton from '../../../components/button/LoadableButton.jsx';
import Preview from './Preview.jsx';
import Delete from '../../../assets/Delete.jsx';
import Add from '../../../assets/Add.jsx';

export default function Documents({ id, tab }) {

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [createFolderForm] = Form.useForm();
  const [uploadFileForm] = Form.useForm();
  const navigate = useNavigate();
  const [isFolderListLoading, setIsFolderListLoading] = useState(false);
  const [isChildFolderListLoading, setIsChildFolderListLoading] = useState(false);
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPreview, setSelectedPreview] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [folderFormValue, setFolderFormValue] = useState({ name: '' });
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [isCreateFolderLoading, setIsCreateFolderLoading] = useState(false);
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [uploadFileFormValue, setUploadFileFormValue] = useState({ files: [] });
  const [isUploadFileLoading, setIsUploadFileLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const [isOpenMoveModal, setIsOpenMoveModal] = useState(false);
  const [isMoveFolderLoading, setIsMoveFolderLoading] = useState(false);
  const [isMoveFolderListLoading, setIsMoveFolderListLoading] = useState(false);
  const [moveBreadcrumbItems, setMoveBreadcrumbItems] = useState([]);
  const [folderListForMove, setFolderListForMove] = useState([]);
  const [folderForMove, setFolderForMove] = useState(null);

  const downloadFileFolderManager = async (id) => {
    try {
      let params = { doc_id: id };
      const { data } = await DownloadFileFolderApi(params);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  }

  const items = [
    {
      label: <div className='flex items-center cursor-pointer gap-3' onClick={() => setIsOpenMoveModal(true)}><img src='/assets/icons/arrows-pointing-out.svg' className='size-4 rotate-45' alt='icon' /><p>Move</p></div>,
      key: '0',
    },
    {
      label: <div className='flex items-center cursor-pointer gap-3' onClick={() => downloadFileFolderManager(selectedItem?.id)}><img src='/assets/icons/download.svg' className='size-4' alt='icon' /><p>Download</p></div>,
      key: '1',
    },
    {
      label: <div className='flex items-center cursor-pointer gap-3 text-primary' onClick={() => setIsDeleteModalOpen(true)}><Delete color='#EF5755'/><p>Delete</p></div>,
      key: '2',
    },
  ];

  const uploadItems = [
    {
      key: '1',
      label: <div onClick={() => setIsCreateFolderModalOpen(true)} className='flex items-center gap-2 font-semibold'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" className="size-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
        Create Folder
      </div>
    },
    {
      key: '2',
      label: <div onClick={() => setIsOpenUploadModal(true)} className='flex items-center gap-2 font-semibold'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" className="size-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
        </svg>
        Upload File
      </div>
    }
  ];

  const getBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    uploadFileForm.setFieldsValue({ files: newFileList });
    if (newFileList?.length == 0) {
      uploadFileForm.setFieldsValue({ files: [] });
    }
  }

  const UploadButton = (
    <button type="button" className='flex flex-col items-center gap-2'>
      <div className="size-7"><img src="/assets/icons/upload.svg" alt="icon" /></div>
      Upload
    </button>
  );

  const getIcons = (file) => {
    const fileExtension = file.split('.').pop().toLowerCase() || null;

    switch (fileExtension) {
      case 'pdf':
        return <img src='/assets/icons/pdf-template.svg' alt='icon' className='size-7' />;
      case 'jpeg':
      case 'jpg':
      case 'png':
        return <img src='/assets/icons/img-template.svg' alt='icon' className='size-7' />;
      case 'doc':
      case 'docx':
        return <img src='/assets/icons/doc-template.svg' alt='icon' className='size-7' />;
      case 'csv':
        return <img src='/assets/icons/csv-template.svg' alt='icon' className='size-7' />;
      case 'xlsx':
        return <img src='/assets/icons/excel-template.svg' alt='icon' className='w-6 h-7' />;
      default:
        return <img src='/assets/icons/folder.svg' alt='icon' className='size-5' />;
    }
  }

  const getDocuments = async () => {
    if (!tab === '3') { return };
    try {
      setIsLoading(true);
      let params = { user_id: id };
      const { data } = await GetClientDocumentsApi(params);
      if (data?.status) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              children: null,
              name: (<div className='flex items-center gap-3 cursor-pointer' onClick={() => item?.type == 'folder' ? openFolder(item) : setSelectedPreview(item)}>{getIcons(item?.name)}<p>{item?.name}</p></div>),
              addedDate: item?.created_at ? moment(item?.created_at).format('lll') : '--',
              actions: (
                <Dropdown
                  menu={{ items }}
                  trigger={['click']}
                  className='cursor-pointer size-6 min-h-6 min-w-6'
                >
                  <img src='/assets/icons/ellipsis-vertical.svg' alt='icon' onClick={() => setSelectedItem(item)} />
                </Dropdown>
              ),
            };
          })
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getDocuments();
  }, [tab]);

  const openFolder = async (folder) => {
    try {
      setIsChildFolderListLoading(true);
      setCurrentFolder(folder);
      const { data } = await GetChildFolderListApi({ folder_id: folder?.id, user_id: id });
      if (data?.status) {
        setBreadcrumbItems(
          data?.breadcrumb?.map((item) => {
            if (item?.parentId) {
              return {
                title: (<p className='cursor-pointer' onClick={() => openFolder({ id: item?.id })} >{item?.name}</p>)
              }
            }
            return null;
          })?.filter(item => item !== null)
        );
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              name: (<div className='flex items-center gap-3 cursor-pointer' onClick={() => item?.type == 'folder' ? openFolder(item) : setSelectedPreview(item)}>{getIcons(item?.name)}<p>{item?.name}</p></div>),
              addedDate: item?.created_at ? moment(item?.created_at).format('lll') : '--',
              actions: (
                <Dropdown
                  menu={{ items }}
                  trigger={['click']}
                  className='cursor-pointer size-6 min-h-6 min-w-6'
                >
                  <img src='/assets/icons/ellipsis-vertical.svg' alt='icon' onClick={() => setSelectedItem(item)} />
                </Dropdown>
              ),
            };
          })
        );
      }
      setIsChildFolderListLoading(false);
    } catch (error) {
      setIsChildFolderListLoading(false);
    }
  }

  const getFolderList = async () => {
    try {
      setIsFolderListLoading(true);
      let params = { user_id: id };
      const { data } = await GetClientDocumentsApi(params);
      if (data?.status) {
        setFolders(data?.data);
        setFolderListForMove(
          data?.data?.map((item) => {
            return {
              ...item,
              children: null,
              name: (<div className='flex items-center gap-3 cursor-pointer' onClick={() => openMoveFolder(item)}><img src='/assets/icons/folder.svg' alt='icon' className='size-5' /><p>{item?.name}</p></div>),
              addedDate: item?.created_at ? moment(item?.created_at).format('lll') : '--',
            };
          })
        );
      }
      setIsFolderListLoading(false);
    } catch (error) {
      setIsFolderListLoading(false);
    }
  }

  useEffect(() => {
    getFolderList();
  }, []);

  useEffect(() => {
    if (!isOpenMoveModal) {
      setFolderListForMove(
        folders?.map((item) => {
          return {
            ...item,
            name: (<div className='flex items-center gap-3 cursor-pointer' onClick={() => openMoveFolder(item)}><img src='/assets/icons/folder.svg' alt='icon' className='size-5' /><p>{item?.original_name}</p></div>),
            addedDate: item?.created_at ? moment(item?.created_at).format('lll') : '--',
          };
        })
      );
      setFolderForMove(null);
      setMoveBreadcrumbItems([]);
    }
  }, [isOpenMoveModal]);

  const openMoveFolder = async (folder) => {
    try {
      setIsMoveFolderListLoading(true);
      setFolderForMove(folder);
      const { data } = await GetChildFolderListApi({ folder_id: folder?.id, user_id: id });
      setMoveBreadcrumbItems(
        data?.breadcrumb?.map((item) => {
          if (item?.parentId) {
            return {
              title: (<p className='cursor-pointer' onClick={() => openMoveFolder({ id: item?.id })} >{item?.name}</p>)
            }
          }
          return null;
        })?.filter(item => item !== null)
      );
      setFolderListForMove(
        data?.data?.map((item) => {
          if (item?.type == 'folder') {
            return {
              ...item,
              name: (<div className='flex items-center gap-3 cursor-pointer' onClick={() => openMoveFolder(item)}><img src='/assets/icons/folder.svg' alt='icon' className='size-5' /><p>{item?.original_name}</p></div>),
              addedDate: item?.created_at ? moment(item?.created_at).format('lll') : '--',
            };
          }
          return null;
        })?.filter(item => item !== null)
      );
      setIsMoveFolderListLoading(false);
    } catch (error) {
      setIsMoveFolderListLoading(false);
    }
  }

  const deleteItemHandler = async () => {
    try {
      setIsDeleteLoading(true);
      const { data } = await DeleteFileFolderApi({ id: selectedItem?.id });
      if (data?.status) {
        setData((prevData) => prevData.filter((item) => item.id !== selectedItem?.id));
        setSelectedItem('');
        setIsDeleteModalOpen(false);
        toast.success(data?.message);
      }
      setIsDeleteLoading(false);
    } catch (error) {
      setIsDeleteLoading(false);
    }
  }

  const createNewFolder = async (values) => {
    if (!currentFolder) {
      toast.error('Please select folder first to create new folder.');
      return;
    }
    try {
      setIsCreateFolderLoading(true);
      let params = {
        ...values,
        parent_id: currentFolder?.id
      }
      const { data } = await UploadFileFolderApi(params);
      if (data?.status) {
        toast.success(data?.message);
        openFolder(currentFolder);
        createFolderForm.resetFields();
        setIsCreateFolderModalOpen(false);
      }
      setIsCreateFolderLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsCreateFolderLoading(false);
    }
  }

  useEffect(() => {
    createFolderForm.resetFields();
  }, [isCreateFolderModalOpen]);

  const uploadFileHandler = async (values) => {
    const extractOriginFileObjs = (fileArray) => {
      return fileArray.map(file => file.originFileObj);
    };
    try {
      if (!currentFolder) {
        toast.error('Please select folder first to upload.');
        return;
      }
      setIsUploadFileLoading(true);
      let params = {
        files: extractOriginFileObjs(values?.files),
        parent_id: currentFolder?.id
      }
      let apiHeader = { headers: { Accept: "application/json", "Content-Type": "multipart/form-data" } };
      const { data } = await UploadFileFolderApi(params, apiHeader);
      if (data?.status) {
        toast.success(data?.message);
        openFolder(currentFolder);
        uploadFileForm.resetFields();
        setIsOpenUploadModal(false);
      }
      setIsUploadFileLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsUploadFileLoading(false);
    }
  }

  useEffect(() => {
    setFileList([]);
    uploadFileForm.resetFields();
  }, [isOpenUploadModal]);

  const handleMove = async () => {
    try {
      setIsMoveFolderLoading(true);
      let params = {
        from_id: selectedItem?.id,
        to_id: folderForMove?.id
      };
      const { data } = await MoveFileFolderApi(params);
      if (data?.status) {
        toast.success(data?.message);
        setData((prevData) => prevData.filter((item) => item.id !== selectedItem?.id));
        setSelectedItem('');
        setIsOpenMoveModal(false);
      }
      setIsMoveFolderLoading(false);
    } catch (error) {
      setIsMoveFolderLoading(false);
    }
  }

  return (
    <div>

      {selectedPreview ? (
        <Preview selectedPreview={selectedPreview} setSelectedPreview={setSelectedPreview} />
      ) : (
        <div className='relative h-[calc(100vh-146px)] overflow-y-auto'>
          <SkeletonTable columns={columns} data={data} isLoading={isLoading} />
          <Dropdown menu={{ items: uploadItems }} trigger='click'>
          <button className='bg-secondary text-White rounded-lg absolute bottom-4 right-4 h-10 w-12 px-3.5'> <Add /> </button>
          </Dropdown>
        </div>
      )}

      {/* --- Delete confirmation modal --- */}
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        message={`Are you sure you want to delete this ${selectedItem?.type == 'folder' ? 'folder' : 'file'}?`}
        onConfirm={deleteItemHandler}
        isLoading={isDeleteLoading}
        loadingLabel={`Deleting ${selectedItem?.type == 'folder' ? 'folder' : 'file'}...`}
      />

      {/* --- Create new folder modal --- */}
      <ModalComponent isOpen={isCreateFolderModalOpen} setIsOpen={setIsCreateFolderModalOpen} title='Create new folder'>
        <Form className='w-full' autoComplete="off" form={createFolderForm} initialValues={folderFormValue} onFinish={createNewFolder}>
          <div className='input-white'>
            <label className='text-base text-[#2F2B3DCC] font-medium'>Folder Name</label>
            <Form.Item name="name" className='mb-0' rules={[{ required: true, message: 'Please enter folder name.' }]}>
              <Input placeholder="Folder name" className="mt-1 w-full ps-4 py-2" />
            </Form.Item>
          </div>
          <LoadableButton
            className='bg-primary text-sm text-white font-medium  px-8 py-2 mt-6 rounded-lg themeHover duration-500'
            type="submit"
            lable='Save'
            isLoading={isCreateFolderLoading}
            loadingLable='Creating folder...'
          />
        </Form>
      </ModalComponent>

      {/* --- UPload file modal --- */}
      <ModalComponent isOpen={isOpenUploadModal} setIsOpen={setIsOpenUploadModal} title='Upload a new file' width={600}>
        <Form className='w-full' autoComplete="off" form={uploadFileForm} initialValues={uploadFileFormValue} onFinish={uploadFileHandler}>
          <div>
            <Form.Item name="files" rules={[{ required: true, message: 'Please upload file.' }]}>
              <Upload
                beforeUpload={() => false}
                multiple
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 5 ? null : UploadButton}
              </Upload>
            </Form.Item>
            {previewImage && (
              <Image
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(''),
                }}
                src={previewImage}
              />
            )}
          </div>
          <LoadableButton
            className='bg-primary text-sm text-white font-medium  px-8 py-2 mt-4 rounded-lg themeHover duration-500'
            type="submit"
            lable='Upload'
            isLoading={isUploadFileLoading}
            loadingLable='Uploading file...'
          />
        </Form>
      </ModalComponent>

      {/* --- Move file modal --- */}
      <ModalComponent isOpen={isOpenMoveModal} setIsOpen={setIsOpenMoveModal} width={800}>
        <div className={`${breadcrumbItems?.length > 0 ? 'table-radius-0' : ''}`}>
          <div className='xl:col-span-3 border rounded-lg relative'>
            {
              moveBreadcrumbItems?.length > 0 &&
              <Breadcrumb
                className='my-3 mx-4'
                separator={
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="var(--text-color)" className="size-4 mt-1">
                    <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
                  </svg>
                }
                items={moveBreadcrumbItems}
              />
            }
            <div className={`${breadcrumbItems?.length > 0 ? 'table-radius-0' : ''}`}>
              <SkeletonTable columns={columns.slice(0, 3)} data={folderListForMove} isLoading={isMoveFolderListLoading} />
            </div>
          </div>
          <div className='flex justify-end'>
            {folderForMove &&
              <LoadableButton
                className='bg-primary text-sm text-white font-medium px-8 py-2 mt-4 rounded-lg themeHover duration-500'
                type="button"
                lable='Move here'
                onClick={() => handleMove()}
                isLoading={isMoveFolderLoading}
                loadingLable={`Moving ${selectedItem?.type == 'folder' ? 'folder' : 'file'}...`}
              />
            }
          </div>
        </div>
      </ModalComponent>
    </div>
  )
}