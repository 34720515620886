import React, { useState, useEffect } from 'react';
import { getCellsData } from '../../../services/CellsProvider.js';
import { Dropdown, Input } from 'antd';
import { AddUpdateQuestionApi } from '../../../api/request/organizer.js';
import toast from 'react-hot-toast';

export default function CellDropdown({ question, setIsLoading }) {

    const [cellsData, setCellsData] = useState([]);
    const [cellValue, setCellValue] = useState(question?.cell_id);

    const handleCellsData = async () => {
        const data = await getCellsData();
        setCellsData(data?.filter(item => item?.cell_information?.length)?.map((item) => {
            return {
                key: item?.id,
                label: item?.name,
                children: item?.cell_information?.map((child) => { return { key: child?.id, label: <div onClick={() => handleSubmit(child?.cell_value)}>{child?.cell_value}</div> } })
            }
        }))
    }

    useEffect(() => {
        handleCellsData();
    }, []);

    const handleSubmit = async (cellId) => {
        setCellValue(cellId);
        try {
            setIsLoading(true);
            let params = {
                question_id: question?.id,
                section_id: question?.organizer_section_id,
                cell_id: cellId
            };
            const { data } = await AddUpdateQuestionApi(params);
            if (data?.status) {

            }
            setIsLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoading(false);
        }
    }

    return (
        <Dropdown menu={{ items: cellsData }} trigger='click'>
            <Input
                size='large'
                placeholder='Add short code'
                className='py-3 ps-4 w-72'
                value={cellValue}
                readOnly
                onClick={(e) => e.preventDefault()}
                suffix={<img className='size-5' src='/assets/icons/dropdown-light.svg' alt='icon' />}
            />
        </Dropdown>
    )
}