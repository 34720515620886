import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Add from '../../../assets/Add'
import Edit from '../../../assets/Edit';
import { useNavigate } from 'react-router-dom';

const TaskDetail = () => {
    const navigate = useNavigate();
    const [items, setItems] = useState([
        { id: '1', title: 'PR File', duration: '10 day', content: 'Add project data, create thematic pages, edit data, share information with team members' },
        { id: '2', title: 'Visa File', duration: '1 month', content: 'Add project data, create thematic pages, edit data, share information with team members' },
    ]);

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const newItems = Array.from(items);
        const [movedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, movedItem);
        setItems(newItems);
    };

    return (
        <div className='border rounded-lg bg-White'>
            <div className='py-5 px-4 flex items-center justify-between border-b'>
                <h2 className='font-semibold text-xl'>Demo</h2>
                <button onClick={() => navigate('/task')} className='bg-secondary flex items-center gap-2 rounded-lg text-White py-2 px-3'>
                    <Add /><span>Add Task</span>
                </button>
            </div>
            <div className='p-5'>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {items.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                className="flex items-center gap-2 mb-3 "
                                            >
                                                <img src="/assets/icons/move-dnd.svg" alt="icon" />
                                                <div className='border rounded-md  w-full p-5 flex items-center justify-between'>
                                                    <div className='space-y-1'>
                                                        <h2 className='font-medium text-lg'>{item.title}</h2>
                                                        <p className='text-Gray text-base'>Time Duration : <span className='text-black'>{item.duration}</span></p>
                                                        <p className='text-Gray text-base'>{item.content}</p>
                                                    </div>
                                                    <button className='border border-secondary rounded-lg text-secondary px-2 py-1 flex gap-2'><Edit color='#1E4387' /> Edit</button>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    )
}

export default TaskDetail