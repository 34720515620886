import React, { useEffect, useState } from 'react';
import SkeletonTable from '../../components/table/SkeletonTable.jsx';
import columns from '../../columns/clients/clients.js';
import moment from 'moment';
import { Switch } from 'antd';
import {GetClientListApi} from '../../api/request/clients/clients.js';
import { useNavigate } from 'react-router-dom';
import View from '../../assets/View.jsx';
import ConfirmationModal from '../../components/global/modals/ConfirmationModal.jsx';

export default function Clients() {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);

  const getClientList = async () => {
    try {
      setIsLoading(true);
      const { data } = await GetClientListApi();
      if (data?.status) {
        setData(data?.data?.map((item) => {
          return {
            ...item,
            status: (<Switch checked={item?.status == 'active' ? true : false} onClick={() => handleClientStatus(item)} checkedChildren='On' unCheckedChildren='Off' />),
            created_at: item?.created_at ? (moment(item?.created_at).format('LL')) : '',
            action: <div className='cursor-pointer' onClick={() => navigate(`/client/${item?.id}`)}><View /></div>
          };
        }));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getClientList();
  }, []);

  const handleClientStatus = (item) => {
    setSelectedClient(item);
    setIsStatusModalOpen(true);
  }

  const handleStatuChange = async () => {
    try {

    } catch (error) {

    }
  }

  return (
    <div>
      <div className='border rounded-xl mb-4 bg-White'>
        <div className='mt-4 mx-5'>
          <p className='font-semibold text-xl'>Clients</p>
        </div>
        <div className='table-radius-0 text-light my-4'>
          <SkeletonTable columns={columns} data={data} isLoading={isLoading} pagination={true} />
        </div>
      </div>

      {/* ------ Confirmation modal for status ------- */}
      <ConfirmationModal
        isOpen={isStatusModalOpen}
        setIsOpen={setIsStatusModalOpen}
        message='Are you sure, you want to change client status?'
        onConfirm={handleStatuChange}
        isLoading={isStatusLoading}
        loadingLabel='Changing status...'
      />
    </div>
  )
}