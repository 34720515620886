import { Button, Drawer, Dropdown, Empty, Spin, Switch, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import Copy from '../../../assets/Copy';
import Setting from '../../../assets/Setting';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import MyFreeEntry from '../../../components/questionContainer/formElements/FreeEntry';
import MyEmail from '../../../components/questionContainer/formElements/Email';
import MyNumber from '../../../components/questionContainer/formElements/Number'
import MyDatePicker from '../../../components/questionContainer/formElements/DatePicker';
import MyRadio from '../../../components/questionContainer/formElements/Radio';
import MyCheckBox from '../../../components/questionContainer/formElements/CheckBox';
import MyDropdown from '../../../components/questionContainer/formElements/Dropdown';
import MyFileUpload from '../../../components/questionContainer/formElements/FileUpload';
import toast from 'react-hot-toast';
import { LoadingOutlined } from '@ant-design/icons';
import { AddUpdateQuestionApi, QuestionListApi, DeleteQuestionApi, DeleteSectionApi, ReOrderQuestionApi, GetConditionalQuestionsApi, AddSectionApi } from '../../../api/request/organizer.js';
import ConfirmationModal from '../../../components/global/modals/ConfirmationModal.jsx';
import LoadableButton from '../../../components/button/LoadableButton.jsx';
const { TextArea } = Input;

const Questions = ({ selectedSection, setSelectedSection }) => {

  const [questions, setQuestions] = useState([]);
  const [isQuestionListLoading, setIsQuestionListLoading] = useState(false);
  const [isAddQuestionLoading, setIsAddQuestionLoading] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isDeleteSectionModalOpen, setIsDeleteSectionModalOpen] = useState(false);
  const [isDeleteSectionLoading, setIsDeleteSectionLoading] = useState(false);
  const [isDeleteQuestionLoading, setIsDeleteQuestionLoading] = useState(false);
  // ----- State of section settings ------ //
  const [allowSectionRepeat, setAllowSectionRepeat] = useState(false);
  const [isSectionSettingsOpen, setIsSectionSettingsOpen] = useState(false);
  const [section, setSection] = useState(selectedSection);
  const [sectionName, setSectionName] = useState(selectedSection?.section_name);
  const [isSectionSettingsLoading, setIsSectionSettingsLoading] = useState(false);
  // ----- State of question settings ------ //
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isSettingsLoading, setIsSettingsLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [conditional, setConditional] = useState(false);
  const [description, setDescription] = useState(false);
  const [descriptionText, setDescriptionText] = useState('');
  const [conditions, setConditions] = useState([]);
  const [conditionalQuestions, setConditionalQuestions] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [isConditionalQuestionsLoading, setIsConditionalQuestionsLoading] = useState(false);

  const items = [
    {
      label: <div className='flex justify-center items-center gap-4 text-black font-medium' onClick={() => addNewQuestion('input')}><img src='/assets/icons/free-entry.svg' /><p>Free Entry</p></div>,
      key: '0',
    },
    {
      label: <div className='flex justify-center items-center gap-4 text-black font-medium' onClick={() => addNewQuestion('email')}><img src='/assets/icons/email.svg' /><p>Email</p></div>,
      key: '1',
    },
    {
      label: <div className='flex justify-center items-center gap-4 text-black font-medium' onClick={() => addNewQuestion('number')}><img src='/assets/icons/number.svg' /><p>Number</p></div>,
      key: '2',
    },
    {
      label: <div className='flex justify-center items-center gap-4 text-black font-medium' onClick={() => addNewQuestion('date')}><img src='/assets/icons/date.svg' /><p>Date</p></div>,
      key: '3',
    },
    {
      label: <div className='flex justify-center items-center gap-4 text-black font-medium' onClick={() => addNewQuestion('radio')}><img src='/assets/icons/radio.svg' /><p>Radio Buttons</p></div>,
      key: '4',
    },
    {
      label: <div className='flex justify-center items-center gap-4 text-black font-medium' onClick={() => addNewQuestion('checkbox')}><img src='/assets/icons/checkbox.svg' /><p>Checkboxes</p></div>,
      key: '5',
    },
    {
      label: <div className='flex justify-center items-center gap-4 text-black font-medium' onClick={() => addNewQuestion('dropdown')}><img src='/assets/icons/dropdown.svg' /><p>Dropdown</p></div>,
      key: '6',
    },
    {
      label: <div className='flex justify-center items-center gap-4 text-black font-medium' onClick={() => addNewQuestion('file')}><img src='/assets/icons/file.svg' /><p>File Upload</p></div>,
      key: '7',
    },
  ];

  useEffect(() => {
    setSection(selectedSection);
    setSectionName(selectedSection?.section_name);
  }, [selectedSection]);

  const fetchComponent = (data) => {
    switch (data?.field_type) {
      case 'input':
        return <MyFreeEntry question={data} />
      case 'email':
        return <MyEmail question={data} />
      case 'number':
        return <MyNumber question={data} />
      case 'date':
        return <MyDatePicker question={data} />
      case 'radio':
        return <MyRadio question={data} />
      case 'checkbox':
        return <MyCheckBox question={data} />
      case 'dropdown':
        return <MyDropdown question={data} />
      case 'file':
        return <MyFileUpload question={data} />
      default:
        break;
    }
  }

  const getQuestionList = async () => {
    try {
      const { data } = await QuestionListApi({ section_id: selectedSection?.id });
      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  }

  const getQuestions = async () => {
    try {
      setIsQuestionListLoading(true);
      const data = await getQuestionList();
      if (data.status) {
        setQuestions(data?.data[0]?.questions?.map((item) => {
          return {
            id: item?.id,
            question: fetchComponent(item),
            ...item,
          }
        }));
      }
      setIsQuestionListLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsQuestionListLoading(false);
    }
  }

  const addNewQuestion = async (type) => {
    try {
      setIsAddQuestionLoading(true);
      const { data } = await AddUpdateQuestionApi({ section_id: selectedSection.id, field_type: type });
      if (data.status) {
        const data = await getQuestionList();
        if (data.status) {
          setQuestions(data?.data[0]?.questions?.map((item) => {
            return {
              id: item?.id,
              question: fetchComponent(item),
              ...item,
            }
          }));
        }
      }
      setIsAddQuestionLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsAddQuestionLoading(false);
    }
  };

  useEffect(() => {
    if (selectedSection) {
      getQuestions();
    }
  }, [selectedSection]);

  const handleQuestionOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(questions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setQuestions(items);
    try {
      let params = {
        question_id: result?.draggableId,
        field_order: result?.destination?.index + 1
      };
      const { data } = await ReOrderQuestionApi(params);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const deleteQuestion = async (question) => {
    setSelectedQuestion(question);
    try {
      setIsDeleteQuestionLoading(true);
      const { data } = await DeleteQuestionApi({ organizer_question_id: question?.id });
      if (data?.status) {
        setQuestions(questions.filter(item => item.id !== question?.id));
        setSelectedQuestion(null);
      }
      setIsDeleteQuestionLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsDeleteQuestionLoading(false);
    }
  }

  const deleteSection = async () => {
    try {
      setIsDeleteSectionLoading(true);
      const { data } = await DeleteSectionApi({ organizer_section_id: selectedSection?.id });
      if (data?.status) {
        setSelectedSection(null);
        setIsDeleteSectionModalOpen(false);
        setQuestions([]);
      }
      setIsDeleteSectionLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsDeleteSectionLoading(false);
    }
  }

  // -------- Question setting functionality -------- //
  const getConditionalQuestions = async () => {
    try {
      setIsConditionalQuestionsLoading(true);
      const { data } = await GetConditionalQuestionsApi({ organizer_type_id: selectedSection?.organizer_type_id });
      if (data?.status) {
        setConditionalQuestions(data?.data);
        setQuestionList(data?.data?.map((item) => { return { value: item?.id, label: item?.field_label } }));
      }
      setIsConditionalQuestionsLoading(false);
    } catch (error) {
      setIsConditionalQuestionsLoading(false);
    }
  }

  const openSettings = (question) => {
    setSelectedQuestion(question);
    getConditionalQuestions();
    setRequired(question?.is_required ? true : false);
    setDescription(question?.description ? true : false);
    setDescriptionText(question?.description);
    setConditional(question?.condition?.length ? true : false);
    setConditions((question?.condition)?.map((item, index) => { return { id: index + 1, ...item } }) || [])
    setIsSettingsOpen(true);
  }

  const closeSettings = () => {
    setIsSettingsOpen(false);
    setSelectedQuestion(null);
    setRequired(false);
    setDescription(false);
    setDescriptionText('');
    setConditions([]);
    setConditional(false);
  }

  const handleSettings = async () => {
    try {
      if (description && !descriptionText) {
        toast.error('Please enter description first.');
        return;
      }
      setIsSettingsLoading(true);
      let params = {
        question_id: selectedQuestion?.id,
        section_id: selectedQuestion?.organizer_section_id,
        is_required: required,
        description: description ? descriptionText : null,
        condition: conditional ? conditions?.length ? conditions?.map(({ id, ...rest }) => rest)?.filter((item) => item.question_id !== null && item.answer !== null) : null : null
      }
      const { data } = await AddUpdateQuestionApi(params);
      if (data?.status) {
        setQuestions((prevQuestions) => prevQuestions.map((question) => question.id === selectedQuestion?.id ? { ...question, ...params } : question));
        closeSettings();
      }
      setIsSettingsLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsSettingsLoading(false);
    }
  }

  const createDropdownOption = (id) => {
    let question = conditionalQuestions?.find((item) => item?.id === id);
    return question?.options?.map((item) => { return { value: item, label: item } }) || [];
  }

  const addNewCondition = () => {
    setConditions((prevConditions) => [...prevConditions, { id: conditions?.length + 1, question_id: null, answer: null }]);
  }

  const removeCondition = (id) => {
    setConditions(conditions.filter(item => item.id !== id));
  }

  const onQuestionChange = (id, value) => {
    setConditions((prevCondition) => prevCondition.map((item) => item.id === id ? { ...item, question_id: value } : item));
  }

  const onAnswerChange = (id, value) => {
    setConditions((prevCondition) => prevCondition.map((item) => item.id === id ? { ...item, answer: value } : item));
  }

  // -------- Question setting functionality -------- //
  const openSectionSettings = () => {
    getConditionalQuestions();
    setAllowSectionRepeat(section?.allow_repeat ? true : false);
    setConditional(section?.condition?.length ? true : false);
    setConditions((section?.condition)?.map((item, index) => { return { id: index + 1, ...item } }) || []);
    setIsSectionSettingsOpen(true);
  }

  const closeSectionSettings = () => {
    setIsSectionSettingsOpen(false);
    setConditions([]);
    setAllowSectionRepeat(false);
    setConditional(false);
  }

  const handleSectionSettings = async () => {
    try {
      setIsSectionSettingsLoading(true);
      let params = {
        section_id: selectedSection?.id,
        organizer_type_id: selectedSection?.organizer_type_id,
        section_name: sectionName,
        allow_repeat: allowSectionRepeat,
        condition: conditional ? conditions?.length ? conditions?.map(({ id, ...rest }) => rest)?.filter((item) => item.question_id !== null && item.answer !== null) : null : null
      }
      const { data } = await AddSectionApi(params);
      if (data?.status) {
        setSelectedSection({ ...selectedSection, ...params });
        setSection({ ...section, ...params });
        closeSectionSettings();
      }
      setIsSectionSettingsLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsSectionSettingsLoading(false);
    }
  }

  return (
    <div className='xl:col-span-3'>
      <div className='w-4/5'>
        <div className='border-2 rounded-lg'>
          {selectedSection &&
            <div className='border-b-2 p-3 flex gap-3 justify-between items-center'>
              <p className='font-bold text-secondaryLight1 line-clamp-1 break-words'>{section?.section_name}</p>
              <div className='flex items-center gap-3'>
                <div onClick={openSectionSettings}><Setting /></div>
                <img src='/assets/icons/trash-red.svg' className='cursor-pointer size-5' onClick={() => setIsDeleteSectionModalOpen(true)} />
              </div>
            </div>
          }
          <div className='p-4'>
            {isQuestionListLoading ? (
              <div className='flex flex-col items-center gap-2 my-16'>
                <Spin />
                <p className='text-secondaryLight1'>Loading...</p>
              </div>
            ) : (
              <div>
                {
                  questions.length ?
                    <DragDropContext onDragEnd={handleQuestionOnDragEnd}>
                      <Droppable droppableId="sections">
                        {(provided) => (
                          <div
                            className="section-list"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {questions.map((question, index) => (
                              <Draggable key={question.id} draggableId={question.id} index={index}>
                                {(provided) => (
                                  <div
                                    className={`section-item flex items-start justify-start font-semibold rounded-md duration-500 ${question.disabled ? 'disabled-item' : ''}`}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className='w-full'>{question?.question}</div>
                                    <div className='flex items-center gap-3 mt-[23px]'>
                                      <img src='/assets/icons/settings.svg' className='cursor-pointer size-5' onClick={() => openSettings(question)} />
                                      {(isDeleteQuestionLoading && selectedQuestion?.id == question?.id) ? (
                                        <Spin indicator={<LoadingOutlined spin />} size="default" />
                                      ) : (
                                        <img src='/assets/icons/trash-red.svg' className='cursor-pointer size-5' onClick={() => deleteQuestion(question)} />
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                            {isAddQuestionLoading && <div className='flex flex-col items-center gap-2 my-12'><Spin /><p className='text-secondaryLight1'>Loading...</p></div>}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext> :
                    <>
                      {isAddQuestionLoading ? <div className='flex flex-col items-center gap-2 my-12'><Spin /><p className='text-secondaryLight1'>Loading...</p></div>
                        :
                        <Empty description={false} className='my-24' />
                      }
                    </>
                }
              </div>
            )}
          </div>
        </div>

        <div className='my-7'>
          <div className='grid grid-cols-2 gap-4'>
            <Dropdown menu={{ items }} trigger={['click']} >
              <div className='p-3 bg-secondaryLight rounded-md cursor-pointer flex items-center justify-center gap-3'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                <p className='font-medium'>Add Question Field</p>
              </div>
            </Dropdown>
            <div className='p-3 bg-secondaryLight rounded-md cursor-pointer flex items-center justify-center gap-3'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
              <p className='font-medium'>Add Text Block</p>
            </div>
          </div>
        </div>
      </div>

      {/* ------ Confirmation modal for delete question ------ */}
      <ConfirmationModal
        isOpen={isDeleteSectionModalOpen}
        setIsOpen={setIsDeleteSectionModalOpen}
        message='Are you sure you want to remove this section?'
        onConfirm={deleteSection}
        isLoading={isDeleteSectionLoading}
        loadingLabel='Removing section...'
      />

      <Drawer title='Settings' open={isSettingsOpen} onClose={closeSettings} width={620} maskClosable={false}>
        <div className='border-2 rounded-lg p-4'>
          <div className='flex items-center gap-4 border-b-2 pb-3'>
            <Switch value={required} onChange={() => setRequired(!required)} />
            <p className='font-semibold text-lg'>Required</p>
          </div>
          <p className='mt-4 text-secondaryLight1'>It is mandatory to respond to this question to submit the organizer.</p>
        </div>

        <div className='border-2 rounded-lg p-4 mt-6'>
          <div className='flex items-center gap-4 border-b-2 pb-3'>
            <Switch value={conditional} onChange={() => setConditional(!conditional)} />
            <p className='font-semibold text-lg'>Conditional</p>
          </div>
          <p className='mt-4 text-secondaryLight1'>Ask question only in certain scenarios.</p>

          {conditional &&
            <div>
              {isConditionalQuestionsLoading ? (
                <div className='flex flex-col items-center gap-2 my-10'><Spin /><p className='text-secondaryLight1'>Loading...</p></div>
              ) : (
                <div>
                  {conditionalQuestions?.length > 0 ? (
                    <div>
                      <div className='mt-4 flex justify-end'>
                        <button className='flex items-center gap-1 text-primary font-semibold' onClick={addNewCondition}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" className="size-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                          </svg>
                          Add
                        </button>
                      </div>
                      <div>
                        {
                          conditions?.map((condition, index) => (
                            <div className='flex items-center gap-3'>
                              <div className='w-full grid grid-cols-2 gap-4 my-4' key={index}>
                                <Select showSearch options={questionList} placeholder='Question' size='large' value={condition?.question_id} onChange={(value) => onQuestionChange(condition?.id, value)} />
                                <Select showSearch options={createDropdownOption(condition?.question_id)} placeholder='Answer' size='large' value={condition?.answer} onChange={(value) => onAnswerChange(condition?.id, value)} />
                              </div>
                              <img src='/assets/icons/trash-red.svg' className='cursor-pointer size-5' onClick={() => removeCondition(condition?.id)} />
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  ) : (
                    <Empty description={false} className='my-2' />
                  )}
                </div>
              )}
            </div>}
        </div>

        <div className='border-2 rounded-lg p-4 mt-6'>
          <div className='flex items-center gap-4 border-b-2 pb-3'>
            <Switch value={description} onChange={() => setDescription(!description)} />
            <p className='font-semibold text-lg'>Description</p>
          </div>
          <p className='mt-4 text-secondaryLight1'>Add instructional text to help clients answer your question.</p>
          {description && <TextArea
            className='mt-4'
            placeholder="Question description..."
            autoSize={{ minRows: 3, maxRows: 5 }}
            value={descriptionText}
            onChange={(e) => setDescriptionText(e.target.value)}
          />}
        </div>

        <div className='mt-6 flex gap-4'>
          <LoadableButton
            className='bg-primary px-10 text-white uppercase text-base font-semibold py-2 rounded-lg'
            isLoading={isSettingsLoading}
            lable='Save'
            loadingLable='Saving...'
            onClick={handleSettings}
          />
          <Button size='large' type='primary' ghost className='py-5 px-10 uppercase font-semibold' onClick={closeSettings}>Cancel</Button>
        </div>
      </Drawer>

      <Drawer title='Section settings' open={isSectionSettingsOpen} onClose={closeSectionSettings} width={620} maskClosable={false}>
        <div className='border-2 rounded-lg p-4'>
          <div className='flex items-center gap-4'>
            <Switch value={allowSectionRepeat} onChange={() => setAllowSectionRepeat(!allowSectionRepeat)} />
            <p className='font-semibold text-lg'>Allow client to repeat</p>
          </div>
        </div>

        <div className='border-2 rounded-lg p-4 mt-6'>
          <div className='border-b-2 pb-3'>
            <p className='font-semibold text-lg'>Section name</p>
          </div>
          <Input size='large' placeholder='Section name' className='mt-4' value={sectionName} onChange={(e) => setSectionName(e.target.value)} />
        </div>

        <div className='border-2 rounded-lg p-4 mt-6'>
          <div className='flex items-center gap-4 border-b-2 pb-3'>
            <Switch value={conditional} onChange={() => setConditional(!conditional)} />
            <p className='font-semibold text-lg'>Conditional</p>
          </div>
          <p className='mt-4 text-secondaryLight1'>Ask question only in certain scenarios.</p>

          {conditional &&
            <div>
              {isConditionalQuestionsLoading ? (
                <div className='flex flex-col items-center gap-2 my-10'><Spin /><p className='text-secondaryLight1'>Loading...</p></div>
              ) : (
                <div>
                  {conditionalQuestions?.length > 0 ? (
                    <div>
                      <div className='mt-4 flex justify-end'>
                        <button className='flex items-center gap-1 text-primary font-semibold' onClick={addNewCondition}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" className="size-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                          </svg>
                          Add
                        </button>
                      </div>
                      <div>
                        {
                          conditions?.map((condition, index) => (
                            <div className='flex items-center gap-3'>
                              <div className='w-full grid grid-cols-2 gap-4 my-4' key={index}>
                                <Select showSearch options={questionList} placeholder='Question' size='large' value={condition?.question_id} onChange={(value) => onQuestionChange(condition?.id, value)} />
                                <Select showSearch options={createDropdownOption(condition?.question_id)} placeholder='Answer' size='large' value={condition?.answer} onChange={(value) => onAnswerChange(condition?.id, value)} />
                              </div>
                              <img src='/assets/icons/trash-red.svg' className='cursor-pointer size-5' onClick={() => removeCondition(condition?.id)} />
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  ) : (
                    <Empty description={false} className='my-2' />
                  )}
                </div>
              )}
            </div>}
        </div>

        <div className='mt-6 flex gap-4'>
          <LoadableButton
            className='bg-primary px-10 text-white uppercase text-base font-semibold py-2 rounded-lg'
            isLoading={isSectionSettingsLoading}
            lable='Save'
            loadingLable='Saving...'
            onClick={handleSectionSettings}
          />
          <Button size='large' type='primary' ghost className='py-5 px-10 uppercase font-semibold' onClick={closeSectionSettings}>Cancel</Button>
        </div>
      </Drawer>
    </div>
  )
}

export default Questions