import React, { useEffect, useState } from 'react';
import columns from '../../../columns/clients/invoice.js';
import SkeletonTable from '../../../components/table/SkeletonTable.jsx';

export default function Invoice() {

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    { id: '3ab1f6e3-4cb2-4d95-9140-fb0d7332bc31', name: '2023 Individual Tax Organizer', amount: '350', status: false },
    { id: '3ab1f1e3-4cb2-4s95-9140-fb0d7332bc31', name: 'Business Tax Organizer 2023', amount: '350', status: false },
    { id: '3ab1f6e3-4cb2-4d95-9110-fb0d7332bc31', name: 'Incorporation 2022', amount: '50', status: true },
  ]);

  useEffect(() => {
    setData(data?.map((item) => {
      return {
        ...item,
        status: <div className={`${item?.status ? 'text-green-500' : 'text-red-500'} font-semibold`}>{item?.status ? 'Paid' : 'Unpaid'}</div>,
        amount: `$ ${item?.amount}`,
      };
    }));
  }, []);

  return (
    <div className='h-[calc(100vh-146px)] overflow-y-auto'>
      <SkeletonTable columns={columns} data={data} isLoading={isLoading} />
    </div>
  )
}