import { Button, Drawer, Dropdown, Empty, Spin, Switch, Input, Select, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';
import { LoadingOutlined } from '@ant-design/icons';
import LoadableButton from '../../../components/button/LoadableButton';
import ConfirmationModal from '../../../components/global/modals/ConfirmationModal';
import MyFreeEntry from '../../../components/questionContainer/formElements/FreeEntry';
import MyEmail from '../../../components/questionContainer/formElements/Email';
import MyNumber from '../../../components/questionContainer/formElements/Number';
import MyDatePicker from '../../../components/questionContainer/formElements/DatePicker';
import MyRadio from '../../../components/questionContainer/formElements/Radio';
import MyCheckBox from '../../../components/questionContainer/formElements/CheckBox';
import MyDropdown from '../../../components/questionContainer/formElements/Dropdown';
import MyFileUpload from '../../../components/questionContainer/formElements/FileUpload';
import ModalComponent from '../../../components/global/modals/ModalComponent';

const { TextArea } = Input;
const { Option } = Select;

const Questions = ({ selectedSection, setSelectedSection }) => {

  const [questions, setQuestions] = useState([]);
  const [isQuestionListLoading, setIsQuestionListLoading] = useState(false);
  const [isAddQuestionLoading, setIsAddQuestionLoading] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isDeleteSectionModalOpen, setIsDeleteSectionModalOpen] = useState(false);
  const [isDeleteSectionLoading, setIsDeleteSectionLoading] = useState(false);
  const [isDeleteQuestionLoading, setIsDeleteQuestionLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  // ----- State of section settings ------ //
  const [allowSectionRepeat, setAllowSectionRepeat] = useState(false);
  const [isSectionSettingsOpen, setIsSectionSettingsOpen] = useState(false);
  const [section, setSection] = useState(selectedSection);
  const [sectionName, setSectionName] = useState(selectedSection?.section_name);
  const [isSectionSettingsLoading, setIsSectionSettingsLoading] = useState(false);
  // ----- State of question settings ------ //
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isSettingsLoading, setIsSettingsLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [conditional, setConditional] = useState(false);
  const [description, setDescription] = useState(false);
  const [descriptionText, setDescriptionText] = useState('');
  const [conditions, setConditions] = useState([]);
  const [conditionalQuestions, setConditionalQuestions] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [isConditionalQuestionsLoading, setIsConditionalQuestionsLoading] = useState(false);

  const items = [
    {
      label: <div className='grid grid-cols-2 place-items-center border-b py-1 px-4 text-black font-medium' onClick={() => addNewQuestion('input')}><img src='/assets/icons/free-entry.svg' /><p>Free Entry</p></div>,
      key: '0',
    },
    {
      label: <div className='grid grid-cols-2 place-items-center border-b py-1 px-4 text-black font-medium' onClick={() => addNewQuestion('email')}><img src='/assets/icons/email-.svg' /><p>Email</p></div>,
      key: '1',
    },
    {
      label: <div className='grid grid-cols-2 place-items-center border-b py-1 px-4 text-black font-medium' onClick={() => addNewQuestion('number')}><img src='/assets/icons/number.svg' /><p>Number</p></div>,
      key: '2',
    },
    {
      label: <div className='grid grid-cols-2 place-items-center border-b py-1 px-4 text-black font-medium' onClick={() => addNewQuestion('date')}><img src='/assets/icons/date.svg' /><p>Date</p></div>,
      key: '3',
    },
    {
      label: <div className='grid grid-cols-2 place-items-center border-b py-1 px-4 text-black font-medium' onClick={() => addNewQuestion('radio')}><img src='/assets/icons/radio.svg' /><p>Radio Buttons</p></div>,
      key: '4',
    },
    {
      label: <div className='grid grid-cols-2 place-items-center border-b py-1 px-4 text-black font-medium' onClick={() => addNewQuestion('checkbox')}><img src='/assets/icons/checkbox.svg' /><p>Checkboxes</p></div>,
      key: '5',
    },
    {
      label: <div className='grid grid-cols-2 place-items-center border-b py-1 px-4 text-black font-medium' onClick={() => addNewQuestion('dropdown')}><img src='/assets/icons/dropdown.svg' /><p>Dropdown</p></div>,
      key: '6',
    },
    {
      label: <div className='grid grid-cols-2 place-items-center border-b py-1 px-4 text-black font-medium' onClick={() => addNewQuestion('yes-no')}><img src='/assets/icons/yes-no.svg' /><p>Yes/No</p></div>,
      key: '7',
    },
    {
      label: <div className='grid grid-cols-2 place-items-center border-b py-1 px-4 text-black font-medium' onClick={() => addNewQuestion('file')}><img src='/assets/icons/file-upload.svg' /><p>File Upload</p></div>,
      key: '8',
    },
  ];

  const addNewQuestion = (type) => {
    const newQuestion = {
      id: Date.now().toString(),
      field_type: type,
      question: `New ${type} Question`,
      is_required: false,
      description: '',
      condition: [],
    };

    setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
  };
  const getQuestions = () => {
  }
  const handleQuestionOnDragEnd = () => {
  };
  const deleteQuestion = (question) => {
  }

  useEffect(() => {
    setSection(selectedSection);
    setSectionName(selectedSection?.section_name);
  }, [selectedSection]);

  const fetchComponent = (data) => {
    switch (data?.field_type) {
      case 'input':
        return <MyFreeEntry question={data} />
      case 'email':
        return <MyEmail question={data} />
      case 'number':
        return <MyNumber question={data} />
      case 'date':
        return <MyDatePicker question={data} />
      case 'radio':
        return <MyRadio question={data} />
      case 'checkbox':
        return <MyCheckBox question={data} />
      case 'dropdown':
        return <MyDropdown question={data} />
      case 'file':
        return <MyFileUpload question={data} />
      default:
        break;
    }
  }

  const openSettings = (question) => {
    setSelectedQuestion(question);
    setRequired(question?.is_required ? true : false);
    setDescription(question?.description ? true : false);
    setDescriptionText(question?.description);
    setConditional(question?.condition?.length ? true : false);
    setConditions((question?.condition)?.map((item, index) => { return { id: index + 1, ...item } }) || [])
    setIsSettingsOpen(true);
  }

  const closeSettings = () => {
    setIsSettingsOpen(false);
    setSelectedQuestion(null);
    setRequired(false);
    setDescription(false);
    setDescriptionText('');
    setConditions([]);
    setConditional(false);
  }

  const createDropdownOption = (id) => {
    let question = conditionalQuestions?.find((item) => item?.id === id);
    return question?.options?.map((item) => { return { value: item, label: item } }) || [];
  }

  const addNewCondition = () => {
    setConditions((prevConditions) => [...prevConditions, { id: conditions?.length + 1, question_id: null, answer: null }]);
  }

  const removeCondition = (id) => {
    setConditions(conditions.filter(item => item.id !== id));
  }

  const onQuestionChange = (id, value) => {
    setConditions((prevCondition) => prevCondition.map((item) => item.id === id ? { ...item, question_id: value } : item));
  }

  const onAnswerChange = (id, value) => {
    setConditions((prevCondition) => prevCondition.map((item) => item.id === id ? { ...item, answer: value } : item));
  }

  // -------- Question setting functionality -------- //
  const openSectionSettings = () => {
    setAllowSectionRepeat(section?.allow_repeat ? true : false);
    setConditional(section?.condition?.length ? true : false);
    setConditions((section?.condition)?.map((item, index) => { return { id: index + 1, ...item } }) || []);
    setIsSectionSettingsOpen(true);
  }

  const closeSectionSettings = () => {
    setIsSectionSettingsOpen(false);
    setConditions([]);
    setAllowSectionRepeat(false);
    setConditional(false);
  }

  return (
    <div className='xl:col-span-3 '>
      <div className='border rounded-xl bg-White flex items-center justify-between p-4 mb-4'>
        <p className='font-semibold'>Create file</p>
        <div className='flex items-center gap-5'>
          <div className='flex items-center gap-2'>
            <img src="/assets/icons/preview.svg" alt="icons" className='size-5' />
            <p>Preview</p>
          </div>
          <div className='flex items-center gap-1'>
            <img src="/assets/icons/settings.svg" alt="icons" className='size-5' />
            <p>Settings</p>
          </div>
        </div>
      </div>
      <div className='border rounded-xl bg-White px-3 py-4'>
        {selectedSection &&
          <div className='p-3 flex gap-3 justify-between items-center'>
            <p className='font-bold text-secondaryLight1 line-clamp-1 break-words'>{section?.section_name}</p>
            <div className='flex items-center gap-3'>
              <div onClick={openSectionSettings}></div>
              {/* <img src='/assets/icons/delete.svg' className='cursor-pointer size-5' onClick={() => setIsDeleteSectionModalOpen(true)} /> */}
              <div className='flex items-center gap-5'>
                <img src="/assets/icons/copy.svg" alt="icons" className='size-5' />
                <img src="/assets/icons/settings.svg" alt="icons" className='size-5' />
              </div>
            </div>
          </div>
        }
        <div className='p-4'>
          {isQuestionListLoading ? (
            <div className='flex flex-col items-center gap-2 my-16'>
              <Spin />
              <p className='text-secondaryLight1'>Loading...</p>
            </div>
          ) : (
            <div>
              {
                questions.length ?
                  <DragDropContext onDragEnd={handleQuestionOnDragEnd}>
                    <Droppable droppableId="sections">
                      {(provided) => (
                        <div
                          className="section-list"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {questions.map((question, index) => (
                            <Draggable key={question.id} draggableId={question.id} index={index}>
                              {(provided) => (
                                <div
                                  className={`section-item flex items-start justify-start font-semibold rounded-md duration-500 ${question.disabled ? 'disabled-item' : ''}`}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className="w-full">{fetchComponent(question)}</div>
                                  <div className='flex items-center gap-3 mt-[23px]'>
                                    <img src='/assets/icons/settings.svg' className='cursor-pointer size-5 ms-2' onClick={() => openSettings(question)} />
                                    {(isDeleteQuestionLoading && selectedQuestion?.id == question?.id) ? (
                                      <Spin indicator={<LoadingOutlined spin />} size="default" />
                                    ) : (
                                      <img src='/assets/icons/delete.svg' className='cursor-pointer size-5' onClick={() => deleteQuestion(question)} />
                                    )}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                          {isAddQuestionLoading && <div className='flex flex-col items-center gap-2 my-12'><Spin /><p className='text-secondaryLight1'>Loading...</p></div>}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext> :
                  <>
                    {isAddQuestionLoading ? <div className='flex flex-col items-center gap-2 my-12'><Spin /><p className='text-secondaryLight1'>Loading...</p></div>
                      :
                      <Empty description={false} className='my-24' />
                    }
                  </>
              }
            </div>
          )}
        </div>
        <div className='my-5'>
          <div className='grid grid-cols-2 gap-4'>
            <Dropdown menu={{ items }} trigger={['click']} >
              <div className='p-3 bg-bgColor rounded-md cursor-pointer flex items-center justify-center gap-3'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                <p className='font-medium'>Add Question Field</p>
              </div>
            </Dropdown>
            <div className='p-3 bg-bgColor rounded-md cursor-pointer flex items-center justify-center gap-3'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
              <p className='font-medium'>Add Text Block</p>
            </div>
          </div>
        </div>

        <div className='flex items-center gap-2 mt-8'>
          <button className='border bg-secondary text-White py-1 px-4 rounded-lg flex items-center'>
            <span className='me-2'>Save & Exit</span>
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.625 1.375L14.25 7M14.25 7L8.625 12.625M14.25 7H0.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
          <button onClick={() => setIsSubmit(true)} className='border border-secondary text-secondary py-1 px-4 rounded-lg '>Save</button>
          <button className='border border-secondary text-secondary py-1 px-4 rounded-lg '>Cancel</button>
        </div>
      </div>

      {/* <div className='my-7'>
        <div className='grid grid-cols-2 gap-4'>
          <Dropdown menu={{ items }} trigger={['click']} >
            <div className='p-3 bg-Gray rounded-md cursor-pointer flex items-center justify-center gap-3'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
              <p className='font-medium'>Add Question Field</p>
            </div>
          </Dropdown>
          <div className='p-3 bg-Gray rounded-md cursor-pointer flex items-center justify-center gap-3'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
            <p className='font-medium'>Add Text Block</p>
          </div>
        </div>
      </div> */}

      {/* ------ Confirmation modal for delete question ------ */}
      <ConfirmationModal
        isOpen={isDeleteSectionModalOpen}
        setIsOpen={setIsDeleteSectionModalOpen}
        message='Are you sure you want to remove this section?'
        onConfirm={'deleteSection'}
        isLoading={isDeleteSectionLoading}
        loadingLabel='Removing section...'
      />

      <Drawer title='Settings' open={isSettingsOpen} onClose={closeSettings} width={620} maskClosable={false}>
        <div className='border rounded-xl p-4'>
          <div className='flex items-center gap-4 border-b pb-3'>
            <Switch value={required} onChange={() => setRequired(!required)} />
            <p className='font-semibold text-lg'>Required</p>
          </div>
          <p className='mt-4 text-secondaryLight1'>It is mandatory to respond to this question to submit the organizer.</p>
        </div>

        <div className='border rounded-xl p-4 mt-6'>
          <div className='flex items-center gap-4 border-b pb-3'>
            <Switch value={conditional} onChange={() => setConditional(!conditional)} />
            <p className='font-semibold text-lg'>Conditional</p>
          </div>
          <p className='mt-4 text-secondaryLight1'>Ask question only in certain scenarios.</p>

          {conditional &&
            <div>
              {isConditionalQuestionsLoading ? (
                <div className='flex flex-col items-center gap-2 my-10'><Spin /><p className='text-secondaryLight1'>Loading...</p></div>
              ) : (
                <div>
                  {conditionalQuestions?.length > 0 ? (
                    <div>
                      <div className='mt-4 flex justify-end'>
                        <button className='flex items-center gap-1 text-primary font-semibold' onClick={addNewCondition}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" className="size-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                          </svg>
                          Add
                        </button>
                      </div>
                      <div>
                        {
                          conditions?.map((condition, index) => (
                            <div className='flex items-center gap-3'>
                              <div className='w-full grid grid-cols-2 gap-4 my-4' key={index}>
                                <Select showSearch options={questionList} placeholder='Question' size='large' value={condition?.question_id} onChange={(value) => onQuestionChange(condition?.id, value)} />
                                <Select showSearch options={createDropdownOption(condition?.question_id)} placeholder='Answer' size='large' value={condition?.answer} onChange={(value) => onAnswerChange(condition?.id, value)} />
                              </div>
                              <img src='/assets/icons/trash-red.svg' className='cursor-pointer size-5' onClick={() => removeCondition(condition?.id)} />
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  ) : (
                    <Empty description={false} className='my-2' />
                  )}
                </div>
              )}
            </div>}
        </div>

        <div className='border rounded-xl p-4 mt-6'>
          <div className='flex items-center gap-4 border-b pb-3'>
            <Switch value={description} onChange={() => setDescription(!description)} />
            <p className='font-semibold text-lg'>Description</p>
          </div>
          <p className='mt-4  '>Add instructional text to help clients answer your question.</p>
          {description && <TextArea
            className='mt-4'
            placeholder="Question description..."
            autoSize={{ minRows: 3, maxRows: 5 }}
            value={descriptionText}
            onChange={(e) => setDescriptionText(e.target.value)}
          />}
        </div>

        <div className='mt-6 flex gap-4'>
          <LoadableButton
            className='bg-primary px-10 text-white text-base font-semibold py-2 rounded-lg'
            isLoading={isSettingsLoading}
            lable='Save'
            loadingLable='Saving...'
          // onClick={handleSettings}
          />
          <Button size='large' type='primary' ghost className='py-5 px-10 font-semibold' onClick={closeSettings}>Cancel</Button>
        </div>
      </Drawer>

      <Drawer title='Section settings' open={isSectionSettingsOpen} onClose={closeSectionSettings} width={620} maskClosable={false}>
        <div className='border rounded-xl p-4'>
          <div className='flex items-center gap-4'>
            <Switch value={allowSectionRepeat} onChange={() => setAllowSectionRepeat(!allowSectionRepeat)} />
            <p className='font-semibold text-lg'>Allow client to repeat</p>
          </div>
        </div>

        <div className='border rounded-xl p-4 mt-6'>
          <div className='border-b pb-3'>
            <p className='font-semibold text-lg'>Section name</p>
          </div>
          <Input size='large' placeholder='Section name' className='mt-4' value={sectionName} onChange={(e) => setSectionName(e.target.value)} />
        </div>

        <div className='border rounded-lg p-4 mt-6'>
          <div className='flex items-center gap-4 border-b pb-3'>
            <Switch value={conditional} onChange={() => setConditional(!conditional)} />
            <p className='font-semibold text-lg'>Conditional</p>
          </div>
          <p className='mt-4 text-secondaryLight1'>Ask question only in certain scenarios.</p>

          {conditional &&
            <div>
              {isConditionalQuestionsLoading ? (
                <div className='flex flex-col items-center gap-2 my-10'><Spin /><p className='text-secondaryLight1'>Loading...</p></div>
              ) : (
                <div>
                  {conditionalQuestions?.length > 0 ? (
                    <div>
                      <div className='mt-4 flex justify-end'>
                        <button className='flex items-center gap-1 text-primary font-semibold' onClick={addNewCondition}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" className="size-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                          </svg>
                          Add
                        </button>
                      </div>
                      <div>
                        {
                          conditions?.map((condition, index) => (
                            <div className='flex items-center gap-3'>
                              <div className='w-full grid grid-cols-2 gap-4 my-4' key={index}>
                                <Select showSearch options={questionList} placeholder='Question' size='large' value={condition?.question_id} onChange={(value) => onQuestionChange(condition?.id, value)} />
                                <Select showSearch options={createDropdownOption(condition?.question_id)} placeholder='Answer' size='large' value={condition?.answer} onChange={(value) => onAnswerChange(condition?.id, value)} />
                              </div>
                              <img src='/assets/icons/delete.svg' className='cursor-pointer size-5' onClick={() => removeCondition(condition?.id)} />
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  ) : (
                    <Empty description={false} className='my-2' />
                  )}
                </div>
              )}
            </div>}
        </div>

        <div className='mt-6 flex gap-4'>
          <LoadableButton
            className='bg-primary px-10 text-white uppercase text-base font-semibold py-2 rounded-lg'
            isLoading={isSectionSettingsLoading}
            lable='Save'
            loadingLable='Saving...'
            onClick={'handleSectionSettings'}
          />
          <Button size='large' type='primary' ghost className='py-5 px-10 uppercase font-semibold' onClick={closeSectionSettings}>Cancel</Button>
        </div>
      </Drawer>
      <ModalComponent isOpen={isSubmit} setIsOpen={setIsSubmit} title='Select Pipeline'>
        <Form className='w-full space-y-2' autoComplete="off">
          <Form.Item name="pipeline" className='mb-2' rules={[{ required: true, message: 'Please select a pipeline!' }]} >
            <Select placeholder="Select Pipeline">
              <Option value="demo" >Demo</Option>
              <Option value="incorporation" >Incorporation</Option>
              <Option value="taxfile" >Taxfile</Option>
            </Select>
          </Form.Item>
          <div className='text-center'>
            <LoadableButton
              className='bg-secondary text-sm text-White font-medium px-8 py-2 mt-6 rounded-lg duration-500 '
              type="submit"
              lable='Submit'
              isLoading={isSubmitLoading}
              loadingLable='submiting...'
            />
          </div>
        </Form>
      </ModalComponent>
    </div>
  )
}

export default Questions