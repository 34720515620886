import { Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom'
import { LoginOtpVeryficationApi, ResendOtpApi } from '../../api/request/auth';
import { setloggedIn, setUserDetails } from "../../store/Slices/userSlice.js";
import LoadableButton from '../../components/button/LoadableButton';
import { useDispatch } from 'react-redux';
import { setCookies } from '../../services/Cookies';


const Otp = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [initialValues, setInitialValues] = useState({ otp: '' });
    const [isOtpLoading, setIsOtpLoading] = useState(false);
    const [timer, setTimer] = useState(59);

    const formatTime = (timer) => {
        const minutes = Math.floor(timer / 60);
        const remainingSeconds = timer % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    };

    useEffect(() => {
        setInterval(() => {
            setTimer(current => current !== 0 ? current - 1 : 0);
        }, 1000);
    }, []);

    const handleSubmit = async (values) => {
        try {
            setIsOtpLoading(true);
            const params = { ...values, email: localStorage.getItem('email') }
            const { data } = await LoginOtpVeryficationApi(params);
            if (data?.status) {
                setCookies('token',data?.token);
                dispatch(setloggedIn(true));
                dispatch(setUserDetails(data?.data));
                localStorage.removeItem('email');
                form.resetFields();
                toast.success(data?.message);
                navigate('/dashboard');
            }
            else if (!data?.status) {
                toast.error(data?.message);
            }
            setIsOtpLoading(false);
        }
        catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsOtpLoading(false);
        }
    }

    const handleResend = async () => {
        try {
            const { data } = await ResendOtpApi({ email: localStorage.getItem('email') });
            if (data?.status) {
                toast.success(data?.message);
                setTimer(59);
            }
        }
        catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
        }
    }


    return (
        <>
            <div className='lg:w-3/5 md:w-[80%] w-full sm:p-0 p-4'>
                <Form autoComplete='off' onFinish={handleSubmit} form={form} initialValues={initialValues} className='space-y-8'>
                    <div className='text-center'>
                        <p className='font-bold sm:text-3xl text-2xl mb-3'>OTP Verification</p>
                        <p className="text-sm text-Gray">Enter the verification code we just sent to your <br /> example@gmail.com</p>
                    </div>
                    <Form.Item name="otp" rules={[{ required: true, },]} >
                        <Input.OTP size='large' style={{ width: '100%' }} />
                    </Form.Item>
                    <div className='text-center'>
                        <p className='text-Gray text-sm mb-2'>A code has been sent to your gamil</p>
                        {timer == 0 ?
                            <p onClick={handleResend} className='font-semibold text-xl cursor-pointer'>Resend</p> :
                            <p className='font-semibold text-xl'>Resend in {formatTime(timer)}</p>
                        }
                    </div>
                    <LoadableButton
                        isLoading={isOtpLoading}
                        type='submit'
                        lable='Verify'
                        img='assets/icons/ArrowRight.svg'
                        loadingLable='Verifying otp...'
                        className='bg-secondary text-white text-base w-full py-2 rounded-md mb-5 flex justify-center items-center'
                    />
                    <div className='text-primary flex items-center justify-center mb-5'><img src="assets/icons/ArrowLeft.svg" alt="icon" className='me-3' />
                        <Link to='/login'>Back to login</Link>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default Otp