import { Form, Button, Input, Select, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ModalComponent from '../../components/global/modals/ModalComponent';
import LoadableButton from '../../components/button/LoadableButton';
import Add from '../../assets/Add';

export default function Chat() {

    const [form] = Form.useForm();
    const messagesEndRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isStartChatLoading, setIsStartChatLoading] = useState(false);
    const [initialValue, setInitialValue] = useState({ activeFile: null });
    const [activeChats, setActiveChats] = useState([
        { id: '1', name: 'Business 2023' },
        { id: '2', name: 'Incorporation ' },
        { id: '3', name: 'Tax File 2020' },
        { id: '4', name: 'Create html game environment for website' },
        { id: '5', name: 'Create html game' }
    ]);
    const [openChat, setOpenChat] = useState(null);
    const [messageList, setMessageList] = useState([]);

    // Default auto scroll for open chat
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messageList]);

    const startChat = async () => {
        try {
            setIsStartChatLoading(true);
            setIsStartChatLoading(false);
        } catch (error) {
            setIsStartChatLoading(false);
        }
    }

    useEffect(() => {
        form.resetFields();
    }, [isModalOpen]);

    const renderActiveChat = () => {
        return (
            <div className='px-2'>
                {activeChats?.map((chat) => (
                    <div key={chat?.id} className={`flex items-center gap-3 cursor-pointer p-2 my-3 rounded-md duration-300 text-secondaryLight2 hover:text-black
                     ${openChat?.id == chat?.id ? 'text-black' : null}`} onClick={() => setOpenChat(chat)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                        </svg>
                        <p className='font-medium line-clamp-1'>{chat?.name}</p>
                    </div>
                ))}
            </div>
        )
    }

    const renderArchiveChat = () => {
        return (
            <div></div>
        )
    }

    return (
        <div>
            {/* <div>
                <div className='flex justify-center items-center h-[calc(100vh-106px)]'>
                    <div>
                        <img src='/assets/images/empty-chat.png' alt='image' className='mx-auto' />
                        <p className='text-center font-bold text-3xl mt-5'>Strat a new conversation</p>
                        <p className='text-center text-secondaryLight2 mt-3 md:w-4/5 mx-auto'>Using this software, you can ask yourquestions and receive articles using artificial intelligence assistant</p>
                        <div className='flex justify-center mt-4'>
                            <button className='px-4 py-2 font-medium bg-secondary text-White rounded-lg flex items-center' onClick={() => setIsModalOpen(true)}>
                                <Add />
                                <span className='ms-2'>Start a new chat</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}

            <div>
                <div className='grid xl:grid-cols-4 md:grid-cols-2 gap-4 mt-1'>
                    <button className='flex justify-center gap-2 items-center bg-primary text-White py-2 rounded-md order-2 md:order-1' onClick={() => setIsModalOpen(true)}>
                        <Add />
                        <span>New Chat</span>
                    </button>
                </div>
                <div className='grid xl:grid-cols-4 md:grid-cols-2 gap-4 mt-6'>
                    <div className='border bg-White rounded-lg h-[calc(100vh-174px)] overflow-y-auto'>
                        <Tabs centered defaultActiveKey="1" tabBarStyle={{ width: '100%' }} className="custom-tabs" items={[{ key: '1', label: 'Active Chat', children: renderActiveChat() }, { key: '2', label: 'Archive', children: renderArchiveChat() }]} />
                    </div>
                    <div className='xl:col-span-3 border rounded-lg py-3 bg-White'>
                        {
                            openChat ? (
                                <div>
                                    <div className='h-[calc(100vh-254px)] overflow-y-auto px-3'>
                                        {/* Sent message */}
                                        <div className='flex justify-end'>
                                            <div className='flex items-start justify-end gap-3 my-4 md:w-4/5'>
                                                <div className='bg-bgColor p-2 rounded-md min-w-10 min-h-10 flex items-center justify-center cursor-pointer'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5">
                                                        <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                                                    </svg>
                                                </div>
                                                <p className='bg-bgColor py-2 px-3 rounded-md font-medium'>What is UI UX Design ?</p>
                                            </div>
                                        </div>
                                        {/* Received message */}
                                        <div className='flex items-start gap-3 my-4'>
                                            <div className='bg-bgColor p-2 rounded-md min-w-10 min-h-10 flex items-center justify-center'><img src='/assets/icons/chat-bot.svg' alt='icon' /></div>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum amet atque voluptate mollitia id velit sapiente, ut consectetur aut eligendi aliquam voluptatum perferendis animi et, itaque magni! Incidunt nihil reiciendis similique corrupti dolores asperiores fuga explicabo cumque earum ex veritatis alias rerum, sit accusantium numquam obcaecati quibusdam fugiat optio id recusandae? Facilis maxime debitis harum consequuntur corrupti labore quasi error architecto magni deserunt distinctio ipsum, eius ipsa aliquam cupiditate quae praesentium aut possimus ea. Tempore laudantium aut veniam illo quisquam, doloribus, quia vero dolore, dignissimos perferendis qui rem iure non corrupti beatae dolores quaerat id nulla? Quasi ratione omnis voluptatum!</p>
                                        </div>
                                        <div ref={messagesEndRef} />
                                    </div>
                                    <div className='px-3'>
                                        <Input
                                            className='bg-bgColor mt-3' size='large'
                                            placeholder='Send a message'
                                            prefix={
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#0BAFD4" className="size-5 cursor-pointer">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
                                                </svg>
                                            }
                                            suffix={
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#0BAFD4" className="size-6 cursor-pointer">
                                                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm.53 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v5.69a.75.75 0 0 0 1.5 0v-5.69l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z" clip-rule="evenodd" />
                                                </svg>
                                            }
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className='flex items-center justify-center h-full'>
                                    <div>
                                        <img src='/assets/images/empty-chat.png' alt='image' className='mx-auto h-80' />
                                        <p className='text-center font-bold text-3xl mt-5'>Strat a new conversation</p>
                                        <p className='text-center text-secondaryLight2 mt-3 md:w-4/5 mx-auto'>Using this software, you can ask yourquestions and receive articles using artificial intelligence assistant</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            {/* --------- Start new chat modal --------- */}
            <ModalComponent isOpen={isModalOpen} setIsOpen={setIsModalOpen} title='Select Your Active File'>
                <Form className='w-full' autoComplete="off" form={form} initialValues={initialValue} onFinish={startChat}>
                    <div className='input-white'>
                        <Form.Item name="activeFile" className='mb-0' rules={[{ required: true, message: 'Please select active file.' }]}>
                            <Select size='large' showSearch placeholder='Select your active file' className="mt-1" options={[]} />
                        </Form.Item>
                    </div>
                    <div className='text-center'>
                        <LoadableButton
                            className='bg-secondary text-sm text-white font-medium uppercase px-8 py-2 mt-6 rounded-lg themeHover duration-500'
                            type="submit"
                            lable='Continue'
                            isLoading={isStartChatLoading}
                            loadingLable='Starting chat...'
                        />
                    </div>
                    <LoadableButton />
                </Form>
            </ModalComponent>

        </div>
    )
}
