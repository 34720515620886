import React, { useState } from 'react';
import { Input, Spin } from 'antd';
import { AddUpdateQuestionApi } from '../../../api/request/organizer.js';
import toast from 'react-hot-toast';
import { LoadingOutlined } from '@ant-design/icons';
import CellDropdown from '../components/CellDropdown.jsx';

export default function MyNumber({ question }) {

    const [value, setValue] = useState(question?.field_label);
    const [isCompleted, setIsCompleted] = useState(value?.length ? true : false);
    const [isLoading, setIsLoading] = useState(false);

    const onChange = (event) => {
        setValue(event?.target?.value);
        setIsCompleted(false);
    }

    const handleSubmit = async () => {
        if (!value) { return; }
        try {
            setIsLoading(true);
            let params = {
                question_id: question?.id,
                section_id: question?.organizer_section_id,
                field_label: value
            };
            const { data } = await AddUpdateQuestionApi(params);
            if (data?.status) {
                setIsCompleted(true);
            }
            setIsLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoading(false);
        }
    }

    return (
        <div className='flex items-center gap-4 my-2'>
            <img src='/assets/icons/double-ellipsis-vertical.svg' className='cursor-grab' />
            <Input
                className='py-3 ps-4'
                size='large'
                placeholder='Number'
                onChange={onChange}
                value={value}
                onBlur={handleSubmit}
            />
            <CellDropdown question={question} setIsLoading={setIsLoading} />
            <div className='h-[22px] w-[22px]'>
                {isLoading ? (
                    <Spin indicator={<LoadingOutlined spin />} className='size-[22px]' />
                ) : (
                    <div className='size-[22px]'>{isCompleted ? <img className='size-6' src='/assets/icons/check-circle.svg' alt='icon' /> : <img className='size-[22px]' src='/assets/icons/x-circle.svg' alt='icon' />}</div>
                )}
            </div>
        </div>
    )
}