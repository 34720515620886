const defaultRender = (value) => (value ? value : '--');

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        render: defaultRender,
    },
    {
        title: 'Size',
        dataIndex: 'size',
        render: defaultRender,
    },
    {
        title: 'Contains',
        dataIndex: 'count_description',
        render: defaultRender,
    },
    {
        title: 'Added Date',
        dataIndex: 'addedDate',
        render: defaultRender,
    },
    {
        title: '',
        dataIndex: 'actions',
        render: defaultRender,
    },
];

export default columns;