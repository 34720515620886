import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Image, Input, Spin, Upload } from 'antd';
import { useNavigate } from 'react-router-dom';
import { div, img } from 'framer-motion/client';
import SkeletonTable from '../../components/table/SkeletonTable';
import columns from '../../columns/document/columns';
import ConfirmationModal from '../../components/global/modals/ConfirmationModal';
import ModalComponent from '../../components/global/modals/ModalComponent';
import LoadableButton from '../../components/button/LoadableButton';
import Add from '../../assets/Add';

const Document = () => {

    const [createFolderForm] = Form.useForm();
    const [uploadFileForm] = Form.useForm();

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [uploadFileFormValue, setUploadFileFormValue] = useState({ files: [] });
    const [folderFormValue, setFolderFormValue] = useState({ name: '' });
    const [selectedItem, setSelectedItem] = useState(null);
    const [previewImage, setPreviewImage] = useState('');
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
    const [isCreateFolderLoading, setIsCreateFolderLoading] = useState(false);
    const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
    const [isUploadFileLoading, setIsUploadFileLoading] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);

    const items = [
        {
            label: <div className='flex items-center cursor-pointer gap-3'><img src='/assets/icons/arrows-pointing-out.svg' className='size-4 rotate-45' alt='icon' /><p>Move</p></div>,
            key: '0',
        },
        {
            label: <div className='flex items-center cursor-pointer gap-3'><img src='/assets/icons/download.svg' className='size-4' alt='icon' /><p>Download</p></div>,
            key: '1',
        },
        {
            label: <div className='flex items-center cursor-pointer gap-3 ' onClick={() => setIsDeleteModalOpen(true)}><img src='/assets/icons/delete.svg' className='size-4' alt='icon' /><p>Delete</p></div>,
            key: '2',
        },
    ];
    const createItem = [
        {
            label: (
              <div
                className="flex items-center cursor-pointer gap-3"
                onClick={() => setIsCreateFolderModalOpen(true)} // Open create modal
              >
                <img src="/assets/icons/add-black.svg" className="size-4" alt="icon" />
                <p>Create</p>
              </div>
            ),
            key: '0',
          },
          {
            label: (
              <div
                className="flex items-center cursor-pointer gap-3"
                onClick={() => setIsOpenUploadModal(true)} // Open upload modal
              >
                <img src="/assets/icons/upload.svg" className="size-4" alt="icon" />
                <p>Upload</p>
              </div>
            ),
            key: '1',
          },
    ];

    const UploadButton = (
        <button type="button" className='flex flex-col items-center gap-2'>
            <div className="size-7"><img src="/assets/icons/upload.svg" alt="icon" /></div>
            Upload
        </button>
    );

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        uploadFileForm.setFieldsValue({ files: newFileList });
        if (newFileList?.length == 0) {
            uploadFileForm.setFieldsValue({ files: [] });
        }
    }
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const getBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

    const tableData = [
        { name: 'Business', size: '20 MB', dateAdded: '24 May 2020' },
        { name: 'Incorporation', size: '50 MB', dateAdded: '24 May 2021' },
        { name: 'Tax file', size: '90 MB', dateAdded: '24 May 2022' },
        { name: 'Tax file', size: '90 MB', dateAdded: '24 May 2023' },
    ];

    useEffect(() => {
        setData(tableData.map((item) => {
            return {
                ...item,
                name: <div className='flex items-center space-x-2'><img src="/assets/icons/folder.svg" alt="icon" /><span>item.name</span></div>,
                actions: (
                    <Dropdown
                        menu={{ items }}
                        trigger={['click']}
                        className='cursor-pointer size-6 min-h-6 min-w-6'
                        onClick={() => setSelectedItem(item)}
                    >
                        <img src='/assets/icons/ellipsis-vertical.svg' alt='icon' />
                    </Dropdown>
                )
            }
        }));
    }, [])

    return (
        <div>
            <div className='grid xl:grid-cols-4 md:grid-cols-2 gap-4 mt-1'>
                <h2 className='font-semibold text-xl ms-1'>John</h2>
                <div className='xl:col-span-3 flex items-center justify-end order-1 md:order-2'>
                    <div className='flex items-center gap-3'>
                        <button className='flex items-center px-4 py-1.5 font-semibold border border-secondary text-secondary  rounded-lg'
                            onClick={() => navigate('/documents_trash')}><img src='/assets/icons/delete.svg' alt='icon' className='size-5 me-2' />Trash</button>
                        <Input size="large" placeholder="Search here..." className='xl:w-80' prefix={
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#B3B3B3" class="size-6">
                                <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z" clip-rule="evenodd" />
                            </svg>}
                        />
                    </div>
                </div>
            </div>

            <div className='grid xl:grid-cols-4 md:grid-cols-2 gap-4 mt-6'>
                <div className='border rounded-lg bg-White'>
                    <div className='border-b p-3 rounded-t-lg'>
                        <p className='font-semibold '>My Folders</p>
                    </div>
                    <div className='px-3 relative h-[calc(100vh-230px)] overflow-y-auto'>
                        <div>
                            <div className={`flex items-center gap-3 cursor-pointer my-3 px-3 py-2 rounded-md duration-300 hover:bg-secondaryLight bg-[#dddddd]`}>
                                <img src='/assets/icons/folder.svg' className='size-5' /><p className='font-medium'>John</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='xl:col-span-3 border rounded-lg relative bg-White'>
                    <SkeletonTable columns={columns} data={data} isLoading={false} />
                    <Dropdown
                        menu={{items: createItem}}
                        trigger={['click']}
                        className='cursor-pointer size-6 min-h-6 min-w-6'
                    >
                        <button className='bg-secondary text-White rounded-lg absolute bottom-4 right-4 h-10 w-12 px-3.5'> <Add /> </button>
                    </Dropdown>
                </div>
            </div>

            {/* --- Delete confirmation modal --- */}
            <ConfirmationModal
                isOpen={isDeleteModalOpen}
                setIsOpen={setIsDeleteModalOpen}
                message={`Are you sure you want to delete this ${selectedItem?.type == 'folder' ? 'folder' : 'file'}?`}
                // onConfirm={deleteItemHandler}
                isLoading={isDeleteLoading}
                loadingLabel={`Deleting ${selectedItem?.type == 'folder' ? 'folder' : 'file'}...`}
            />

            {/* --- Create new folder modal --- */}
            <ModalComponent isOpen={isCreateFolderModalOpen} setIsOpen={setIsCreateFolderModalOpen} title='Create new folder'>
                <Form className='w-full' autoComplete="off" form={createFolderForm} initialValues={folderFormValue} onFinish={null}>
                    <div className='input-white'>
                        <label className='text-base text-[#2F2B3DCC] font-medium'>Folder Name</label>
                        <Form.Item name="name" className='mb-0' rules={[{ required: true, message: 'Please enter folder name.' }]}>
                            <Input placeholder="Folder name" className="mt-1 w-full ps-4 py-2" />
                        </Form.Item>
                    </div>
                    <LoadableButton
                        className='bg-primary text-sm text-white font-medium uppercase px-8 py-2 mt-6 rounded-lg themeHover duration-500'
                        type="submit"
                        lable='Save'
                        isLoading={isCreateFolderLoading}
                        loadingLable='Creating folder...'
                    />
                </Form>
            </ModalComponent>

            {/* --- UPload file modal --- */}
            <ModalComponent isOpen={isOpenUploadModal} setIsOpen={setIsOpenUploadModal} title='Upload a new file' width={600}>
                <Form className='w-full' autoComplete="off" form={uploadFileForm} initialValues={uploadFileFormValue} onFinish={null}>
                    <div>
                        <Form.Item name="files" rules={[{ required: true, message: 'Please upload file.' }]}>
                            <Upload
                                beforeUpload={() => false}
                                multiple
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                            >
                                {fileList.length >= 5 ? null : UploadButton}
                            </Upload>
                        </Form.Item>
                        {previewImage && (
                            <Image
                                wrapperStyle={{ display: 'none' }}
                                preview={{
                                    visible: previewOpen,
                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                }}
                                src={previewImage}
                            />
                        )}
                    </div>
                    <LoadableButton
                        className='bg-primary text-sm text-white font-medium uppercase px-8 py-2 mt-4 rounded-lg themeHover duration-500'
                        type="submit"
                        lable='Upload'
                        isLoading={isUploadFileLoading}
                        loadingLable='Uploading file...'
                    />
                </Form>
            </ModalComponent>
        </div>
    )
}

export default Document