import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Avatar, Form, Input, Spin } from 'antd';
import toast from 'react-hot-toast';
import ModalComponent from '../../../components/global/modals/ModalComponent';
import LoadableButton from '../../../components/button/LoadableButton';
import Add from '../../../assets/Add';

const Section = ({ selectedSection, setSelectedSection }) => {

    const [form] = Form.useForm();
    const initialValues = { section_name: "" }
    const [sections, setSections] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sectionLoading, setSectionLoading] = useState(false);

   
    // Function to handle drag and drop
    const onDragEnd = (result) => {
        const { destination, source } = result;

        // If there's no destination, exit
        if (!destination) return;

        // If the item is dropped in the same place, do nothing
        if (destination.index === source.index) return;

        // Reorder the sections array
        const reorderedSections = Array.from(sections);
        const [removed] = reorderedSections.splice(source.index, 1); // Remove from original index
        reorderedSections.splice(destination.index, 0, removed); // Add to new index

        setSections(reorderedSections);
    };


    const onFinish = (values) => {
        setIsLoading(true);

        const newSection = {
            id: Date.now().toString(),
            section_name: values.section_name,
        };
        setSections([...sections, newSection]);
        setTimeout(() => {
            setIsLoading(false);
            setIsOpen(false);
            form.resetFields();
            toast.success('Section added successfully!');
        }, 1000);
    };

    useEffect(() => {
        if (selectedSection == null) {
        }
        setSections(prevSection => prevSection.map((item) => item.id === selectedSection?.id ? selectedSection : item));
    }, [selectedSection]);

    useEffect(() => {
        form.resetFields();
    }, [isOpen]);

    return (
        <div className='border rounded-xl bg-White'>
            <div className='flex items-center justify-between border-b p-3 '>
                <p className='font-semibold text-primary'>New Section</p>
                <div onClick={e => setIsOpen(true)}>
                    <Add color='#0BAFD4' />
                </div>
            </div>
            <div className='sidebar-container px-3 py-8 '>
                {
                    sectionLoading ? <div className='flex justify-center items-center h-full'><Spin /></div> :
                        <DragDropContext onDragEnd={onDragEnd} >
                            <Droppable droppableId="sections">
                                {(provided) => (
                                    <div
                                        className="section-list"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {sections.map((section, index) => (
                                            <Draggable key={section.id} draggableId={section.id} index={index}>
                                                {(provided) => (
                                                    <div
                                                        className={`section-item section-shadow mb-6 flex items-center font-semibold rounded-md duration-500 
                                                            ${section.id == selectedSection?.id ? 'text-primary' : ''}`}
                                                        onClick={() => setSelectedSection(section)}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <Avatar shape='square' className={`cursor-pointer ${index < 4 ? 'border-primary border-2 font-semibold bg-transparent text-primary' : 'bg-white border-2 border-Gray font-semibold text-Gray'} me-2`}>{index + 1}</Avatar>
                                                        <p className='line-clamp-1 break-words'>{section.section_name}</p>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                }
            </div>
            <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} title={"Add New Section"}>
                <Form autoComplete='off' form={form} initialValues={initialValues} onFinish={onFinish}>
                    <Form.Item name="section_name" rules={[{ required: true, message: 'Please enter organizername!', },]}>
                        <Input placeholder='Enter section name...' size='large' />
                    </Form.Item>
                    <div className='flex justify-center '>
                        <LoadableButton
                            className='bg-primary text-white text-base font-semibold w-full py-2 rounded-lg mt-2'
                            type='submit'
                            lable='Submit'
                            loadingLable='Adding Section...'
                            isLoading={isLoading}
                        />
                    </div>
                </Form>
            </ModalComponent>
        </div>
    )
}

export default Section