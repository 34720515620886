import React, { useEffect, useState } from 'react';
import { Dropdown, Input } from 'antd';
import ConfirmationModal from '../../../components/global/modals/ConfirmationModal';
import SkeletonTable from '../../../components/table/SkeletonTable';
import columns from '../../../columns/trash/columns';


export default function Trash() {

    const [selectedItem, setSelectedItem] = useState(null);
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [isRestoreModalOpen, setIsRestoreModalOpen] = useState(false);
    const [isRestoreLoading, setIsRestoreLoading] = useState(false);

    const items = [
        {
            label: <div className='flex items-center cursor-pointer gap-3' onClick={() => setIsRestoreModalOpen(true)}><img src='/assets/icons/arrows-pointing-out.svg' className='size-4' alt='icon' /><p>Restore</p></div>,
            key: '1',
        },
        {
            label: <div className='flex items-center cursor-pointer gap-3 text-primary' onClick={() => setIsDeleteModalOpen(true)}><img src='/assets/icons/delete.svg' className='size-4' alt='icon' /><p>Delete Permanently</p></div>,
            key: '2',
        },
    ];

    const tableData = [
        { name: 'Business', size: '20 MB', deletedDate: '24 May 2020' },
        { name: 'Incorporation', size: '50 MB', deletedDate: '24 May 2021' },
        { name: 'Tax file', size: '90 MB', deletedDate: '24 May 2022' },
        { name: 'Tax file', size: '90 MB', deletedDate: '24 May 2023' },
    ];


    useEffect(() => {
        setData(tableData.map((item) => {
            return {
                ...item,
                name: <div className='flex items-center space-x-2'><img src="/assets/icons/folder.svg" alt="icon" /><span>item.name</span></div>,
                actions: (
                    <Dropdown
                        menu={{ items }}
                        trigger={['click']}
                        className='cursor-pointer size-6 min-h-6 min-w-6'
                        onClick={() => setSelectedItem(item)}
                    >
                        <img src='/assets/icons/ellipsis-vertical.svg' alt='icon' />
                    </Dropdown>
                )
            }
        }));
    }, [])


    return (
        <div>
            {
                data?.length !== 0 ? (
                    <div>
                        <div>
                            <Input size="large" placeholder="Search here..." className='xl:w-80' prefix={
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#B3B3B3" class="size-6">
                                    <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z" clip-rule="evenodd" />
                                </svg>}
                            />
                        </div>
                        <div className='border rounded-lg mt-5 bg-White'>
                            <div className='mb-5'>
                                <SkeletonTable columns={columns} data={data} isLoading={false} />
                            </div>
                        </div>
                    </div>) :
                    (<div className='h-[calc(100vh-106px)] flex items-center justify-center'>
                        <div>
                            <img src='assets/images/trash-empty.png' className='w-96 mx-auto' alt='image' />
                            <p className='text-center font-bold text-3xl mt-4'>Nothing in trash</p>
                            <p className='text-center text-secondaryLight2 mt-3 md:w-4/5 mx-auto'>Move items you don’t need to trash. Items in trash will be deleted forever after 30 days.
                            </p>
                        </div>
                    </div>)}


            {/* --- Delete confirmation modal --- */}
            <ConfirmationModal
                isOpen={isDeleteModalOpen}
                setIsOpen={setIsDeleteModalOpen}
                message={`Are you sure you want to permanently delete this ${selectedItem?.type == 'folder' ? 'folder' : 'file'}?`}
                subMessage='You will not able to revert this changes again.'
                // onConfirm={deleteItemHandler}
                isLoading={isDeleteLoading}
                loadingLabel={`Deleting ${selectedItem?.type == 'folder' ? 'folder' : 'file'}...`}
            />

            {/* --- restore confirmation modal --- */}
            <ConfirmationModal
                isOpen={isRestoreModalOpen}
                setIsOpen={setIsRestoreModalOpen}
                message={`Are you sure you want to restore this ${selectedItem?.type == 'folder' ? 'folder' : 'file'}?`}
                // onConfirm={restoreItemHandler}
                type='success'
                isLoading={isRestoreLoading}
                loadingLabel={`Restoring ${selectedItem?.type == 'folder' ? 'folder' : 'file'}...`}
            />
        </div>
    )
}