import React from 'react'
import ReactApexChart from 'react-apexcharts'

const ColumnBarChart = ({pendingData,completedData }) => {
    const options = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '40%',
                borderRadius: 10,
                borderRadiusApplication: 'end',
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['MonDay', 'TuesDay', 'WednesDay', 'ThursDay', 'FriDay', 'SaturDay', 'SunDay'],
            labels: {
                style: {
                    colors: '#959FA3',
                },
            },
        },
        yaxis: {

            labels: {
                style: {
                    colors: '#959FA3',
                },
            },
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            style: {
                color: '#000000 !important',
                backgroundColor: "#000"
            }
        },
        colors: ['#1E4387', '#959FA3'],
        legend: {
            show: false
        },

    }

    const series = [
        {
            name: 'Completed',
            data: completedData || [],
        },
        {
            name: 'Pending',
            data: pendingData || [],
        }
    ]
    return (
        <div>
            <ReactApexChart options={options} series={series} type="bar" height={350} />
        </div>
    )
}

export default ColumnBarChart