import { Avatar } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

export default function Header({ collapsed, setCollapsed, isDrawerOpen, setIsDrawerOpen, title }) {
  const userDetails = useSelector((state) => state.user.userDetails);

  return (
    <div className='border-b p-4 bg-White'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-4'>
          <svg onClick={() => setCollapsed(!collapsed)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 cursor-pointer hidden lg:block">
            <path fill-rule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />
          </svg>
          <svg onClick={() => setIsDrawerOpen(!isDrawerOpen)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 cursor-pointer lg:hidden">
            <path fill-rule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />
          </svg>
          <p className="text-xl font-bold capitalize hidden md:block">{title}</p>
        </div>
        <div className='flex items-center gap-3 divide-x-2'>
          <div className='border-Gray border h-10  w-10 rounded-full flex justify-center items-center'>
            <img src='/assets/icons/notification-bell.svg' className='h-6 cursor-pointer' alt='icon' />
          </div>
          <div className='ps-3'>
            <div className='flex items-center gap-3 cursor-pointer'>
              <Avatar size='large' className='bg-primary uppercase'>{(userDetails?.first_name)?.charAt(0)}{(userDetails?.last_name)?.charAt(0)}</Avatar>
              <div>
                <p className='leading-5 capitalize font-medium'>{userDetails?.name}</p>
                <p className='text-sm'>{userDetails?.email}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}