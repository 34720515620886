import React, { useEffect, useState } from 'react';
import ModalComponent from '../../components/global/modals/ModalComponent';
import LoadableButton from '../../components/button/LoadableButton'
import { Form, Input } from 'antd'
import { CreateOrganizerApi, OrganizerTypeListApi, CreateServiceType } from '../../api/request/organizer'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import SkeletonTable from '../../components/table/SkeletonTable';
import columns from '../../columns/services/column';
import View from '../../assets/View';

const OrganizerInit = () => {

    const [form] = Form.useForm();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [organizerTypeList, setOrganizerTypeList] = useState([]);
    const initialValues = { organizerName: "" };
    const navigate = useNavigate();


    const addService = async (value) => {
        try {
            setIsLoading(true)
            const { data } = await CreateServiceType(value);
            if (data.status) {
                setIsLoading(false);
                setIsOpen(false);
                toast.success(data.message);
            }
        } catch (err) { }
    }

    const getDocumentTypeList = async () => {
        try {
            const { data } = await OrganizerTypeListApi();
            if (data.status) {
                setOrganizerTypeList(data?.data.map((item) => {
                    return {
                        ...item,
                        file: <p className='hover:text-primary'>{item.file}</p>,
                        actions: <button onClick={e => navigate(`/organizer/${item?.id}`)}><View /></button>
                    }
                }) || []);
            }
        } catch (err) { }
    }

    useEffect(() => {
        getDocumentTypeList();
    }, [])

    return (
        <>
            {/* <div className="flex">
                <button className='ms-auto m-2 px-4 py-2 bg-primary text-white rounded-md' onClick={e => setIsOpen(true)}>Create Organizer</button>
            </div>
            <div className='grid grid-cols-4 mt-4 gap-5'>
                {
                    organizerTypeList?.map(item => {
                        return (
                            <div className='border rounded-md py-3 px-4'>
                                <div className="flex justify-between items-center">
                                    <h2>{item?.organizer_name}</h2>
                                    <button className='bg-primary px-2 py-1 rounded-md text-white' onClick={e => navigate(`/organizer/${item?.id}`)}>Edit</button>
                                </div>
                            </div>
                        )
                    })
                }
            </div> */}
            <div className='border bg-White rounded-xl py-5'>
                <div className='flex items-center justify-between py-4 px-6 mb-2'>
                    <h2 className='font-semibold text-xl'>Services</h2>
                    <div>
                        <button className='ms-auto m-2 px-4 py-2 bg-secondary text-white rounded-md' onClick={e => setIsOpen(true)}> Create Service</button>
                    </div>
                </div>
                <div>
                    <SkeletonTable columns={columns} data={organizerTypeList} />
                </div>
                <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} title={"Enter Service in Name"}>
                    <Form autoComplete='off' form={form} initialValues={initialValues} onFinish={addService}>
                        <Form.Item name="service_name" rules={[{ required: true, message: 'Please enter service name!', },]}>
                            <Input placeholder='Enter Service name...' size='large' />
                        </Form.Item>
                        <Form.Item name="charge" rules={[{ required: true, message: 'Please enter charge!', },]}>
                            <Input placeholder='Enter Charge...' size='large' />
                        </Form.Item>
                        <Form.Item name="description" rules={[{ required: true, message: 'Please enter description!', },]}>
                            <Input placeholder='Enter Description...' size='large' />
                        </Form.Item>
                        <div className='flex justify-center '>
                            <LoadableButton
                                className='bg-secondary text-white text-base font-semibold w-full py-2 rounded-lg mt-2'
                                type='submit'
                                lable='Submit'
                                loadingLable='Creating Service...'
                                isLoading={isLoading}
                            />
                        </div>
                    </Form>
                </ModalComponent>
            </div>
        </>
    )
}

export default OrganizerInit