import React from 'react';
import { Spin } from 'antd';

export default function LoadableButton({ lable, img, type = "button", isLoading = false, loadingLable = "Loading...", className, onClick, disabled = false }) {
    return (
        <button
            type={type}
            disabled={disabled || isLoading}
            onClick={onClick}
            className={`${className} duration-500 ${isLoading ? "opacity-70 cursor-not-allowed" : null} `}
        >
            {isLoading ? (
                <div className='ant-white-spin space-x-4'>
                    <Spin />
                    <span >{loadingLable}</span>
                </div>

            ) : (
                <>
                    <span className='me-2'>{lable}</span>
                    {img && <img src="assets/icons/ArrowRight.svg" alt="" />}
                </>
            )}
        </button>
    )
}