import React, { useState } from 'react';
import { sidebarData } from './sidebarData/sidebarData.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Logout from '../../../assets/Logout.jsx';
import { removeCookies } from '../../../services/Cookies.js';
import { setloggedIn } from '../../../store/Slices/userSlice.js';
import { LogoutApi } from '../../../api/request/auth.js';
import toast from 'react-hot-toast';
import ConfirmationModal from '../modals/ConfirmationModal.jsx';

const Sidebar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getFirstPath = (path) => {
        const parts = path.split('/');
        return parts.length > 1 ? '/' + parts[1] : path;
    }

    const isActivePath = (path) => {
        let active = false;
        path?.map((item) => {
            if (item == (getFirstPath(pathname))) {
                active = true;
            }
        })
        return active;
    }
    const logoutHandler = async () => {
        try {
            setIsLoading(true);
            const { data } = await LogoutApi();
            if (data?.status) {
                toast.success(data?.message);
            }
            localStorage.clear();
            dispatch(setloggedIn(false));
            removeCookies('token');
            navigate('/');
            setIsLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            localStorage.clear();
            dispatch(setloggedIn(false));
            removeCookies('token');
            navigate('/');
            setIsLoading(false);
        }
    }
    return (
        <div className='w-[250px] fixed border-r'>
            <div className='border-b p-4'>
                <div><img src="/assets/icons/logo.svg" alt="logo" className='h-10 w-full object-contain' /></div>
            </div>

            <div className='h-[calc(100vh-158px)] overflow-y-auto pr-4 my-3'>
                {
                    sidebarData.map((item, index) => {
                        const iconWithProps = item?.icon ? React.cloneElement(item?.icon, { active: isActivePath(item?.activeUrl) }) : null;
                        return (
                            <div key={index} className='flex items-center gap-3 my-3 mb-5 ps-4'>
                                <div onClick={() => navigate(item?.url)} className={`font-medium cursor-pointer duration-200 w-full ${isActivePath(item?.activeUrl) ? ' text-White bg-primary rounded-lg font-semibold' : null}`}>
                                    <button className='flex items-center w-full py-2 px-6'>
                                        {iconWithProps}
                                        <p className='text-base ms-3'>{item?.title}</p>
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className='px-4 pb-5'>
                <button onClick={() => setIsModalOpen(true)} className='flex items-center justify-center gap-3 bg-secondary text-white uppercase text-base font-semibold w-full py-2 rounded-lg'>
                    <Logout />
                    <p>Logout</p>
                </button>
            </div>

            {/* --- Loguot confirmation modal --- */}
            <ConfirmationModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                message='Are you sure you want to logout?'
                onConfirm={logoutHandler}
                isLoading={isLoading}
                loadingLabel='Loging out...'
            />

        </div>
    )
}

export default Sidebar