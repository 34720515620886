import React, { useState } from 'react';
import Section from './components/Section.jsx';
import Questions from './components/Questions.jsx';

export default function Organizer() {

  const [selectedSection, setSelectedSection] = useState(null);

  return (
    <div>
      <div className='grid xl:grid-cols-4 gap-4'>
        <Section selectedSection={selectedSection} setSelectedSection={setSelectedSection}></Section>

        <div className='xl:col-span-3'>
          <Questions selectedSection={selectedSection} setSelectedSection={setSelectedSection}></Questions>
        </div>
      </div>
    </div >
  )
}