import api from "../../index";

export const GetClientListApi = () => api.post('getclientlist');
export const GetClientByIdApi = (data) => api.post('clientinfo', data);

// Documents
export const GetClientDocumentsApi = (data) => api.post('filefolderlist', data);
export const DownloadFileFolderApi = (data) => api.post('download-file-folder', data);
export const GetChildFolderListApi = (data) => api.post('viewfilefolder', data);
export const DeleteFileFolderApi = (data) => api.post('deletefilefolder', data);
export const UploadFileFolderApi = (data, headers) => api.post('uploadfilefolder', data, headers);
export const MoveFileFolderApi = (data) => api.post('movefilefolder', data);

// Organizer
export const GetClientOrganizerApi = (data) => api.post('client-org-list', data);