import { Breadcrumb, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GetClientByIdApi } from '../../../api/request/clients/clients.js';
import Overview from './Overview.jsx';
import Information from './Information.jsx';
import Documents from './Documents.jsx';
import Communication from './Communication.jsx';
import Invoice from './Invoice.jsx';
import Email from './Email.jsx';
import Notes from './Notes.jsx';

export default function Client() {

  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [client, setClient] = useState(null);
  const [breadcrumbItems, setBreadcrumbItems] = useState([{ title: <p className='cursor-pointer font-semibold text-black' onClick={() => navigate('/clients')}>Clients</p> }]);
  const [tabs, setTabs] = useState([
    { id: '1', title: 'Overview' },
    { id: '2', title: 'Information' },
    { id: '3', title: 'Documents' },
    { id: '4', title: 'Communication' },
    { id: '5', title: 'Invoice' },
    { id: '6', title: 'Email' },
    { id: '7', title: 'Notes' },
  ]);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const getClientDetails = async () => {
    if (!id) { return; }
    try {
      setIsLoading(true);
      let params = { id: id };
      const { data } = await GetClientByIdApi(params);
      if (data?.status) {
        setClient(data?.data);
        setBreadcrumbItems(current => [...current, { title: <p className='capitalize'>{data?.data?.name}</p> }, { title: <p className='capitalize'>{activeTab?.title}</p> }]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getClientDetails();
  }, [id]);

  const changeTab = (tab) => {
    setActiveTab(tab);
    setBreadcrumbItems(current => [...(current?.slice(0, 1)), { title: <p className='capitalize'>{tab?.title}</p> }]);
  }

  const renderTab = () => {
    switch (activeTab?.id) {
      case '1':
        return <Overview />;
      case '2':
        return <Information client={client} />;
      case '3':
        return <Documents id={id} tab={activeTab} />;
      case '4':
        return <Communication />;
      case '5':
        return <Invoice />;
      case '6':
        return <Email />;
      case '7':
        return <Notes />;
      default:
        return <div className='flex items-center justify-center h-full text-secondaryLight1'>Select a tab</div>;
    }
  }

  return (
    <div>
      {
        isLoading ? (
          <div className='flex flex-col items-center gap-2 mt-48'>
            <Spin />
            <p className='text-secondaryLight1'>Loading...</p>
          </div>
        ) : (
          <div>
            <div>
              <Breadcrumb items={breadcrumbItems} />
            </div>
            <div className='grid md:grid-cols-4 gap-4 mt-4'>

              <div className='border rounded-lg bg-White'>
                <div className='border-b p-3  rounded-t-lg'>
                  <p className='font-semibold '>List</p>
                </div>
                <div className='relative h-[calc(100vh-194px)] overflow-y-auto'>
                  {tabs?.map((tab, index) => (
                    <div key={index} onClick={() => changeTab(tab)} className={`cursor-pointer my-3 py-2 font-medium px-3 duration-300 ${activeTab?.id == tab?.id ? 'bg-[#EBEBEB] text-primary font-semibold' : ''}`}>
                      {tab?.title}
                    </div>
                  ))}
                </div>
              </div>

              <div className='md:col-span-3 border rounded-lg relative bg-White'>
                {renderTab()}
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}