import React, { useEffect, useState } from 'react';
import ColumnBarChart from '../../components/chart/ColumnBarChart';
import AreaChart from '../../components/chart/AreaChart';

const Dashboard = () => {

  const dashboardCards = [
    { title: 'Active job by files', total: '10', icon: '/assets/icons/active-job-files.svg', bgColor: 'bg-[#E5FAFF]' },
    { title: 'Completed files', total: '30', icon: '/assets/icons/completed-files.svg', bgColor: 'bg-[#DDF6E8]' },
    { title: 'Overdue files', total: '20', icon: '/assets/icons/overdue-files.svg', bgColor: 'bg-[#cdd8ec]' },
    { title: 'Pending tickets', total: '20', icon: '/assets/icons/pending-tickets.svg', bgColor: 'bg-[#cfcced]' },
  ];
  const totalClientData = { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'], series: [{ name: 'Client', data: [12, 10, 28, 8, 19, 34, 49] }] };
  const signatureData = { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'], series: [{ name: 'Client', data: [12, 10, 28, 16, 31, 21, 38] }] };

  return (
    <>
      <div>
        <div className='grid sm:grid-cols-4 gap-3'>
          {dashboardCards.map((item, index) =>
            <div key={index} className='border rounded-xl p-4 bg-white'>
              <div className='flex justify-between items-center'>
                <div>
                  <p className='font-medium'>{item.title}</p>
                  <p className='font-semibold text-2xl mt-2'>{item.total}</p>
                </div>
                <div className={`${item.bgColor} rounded-xl size-14 flex items-center justify-center`}>
                  <img src={item.icon} alt='icon' />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='flex sm:flex-row flex-col mt-5 gap-3'>
          <div className='border rounded-xl p-4 bg-White w-full'>
            <div className='flex items-center justify-end gap-3 text-sm px-10'>
              <p><span className='text-xl text-secondary'>●</span> Complete</p>
              <p><span className='text-xl text-Gray '>●</span> Pending</p>
            </div>
            <div>
              <ColumnBarChart pendingData={[500, 100, 350, 150, 200, 180, 300]} completedData={[400, 480, 320, 360, 270, 200, 180]} />
            </div>
          </div>

          <div className='sm:w-1/2 grid sm:grid-cols-2 gap-3'>
            <div className='flex flex-col justify-between gap-3'>
              <div className='border rounded-xl bg-White'>
                <div className='p-4'>
                  <p className='font-medium line-clamp-1'>Total Client</p>
                  <p className='font-semibold text-2xl mt-1'>135</p>
                </div>
                <AreaChart data={totalClientData} colors={['#7367F0']} height={210} />
              </div>
              <div className='border rounded-xl bg-White p-4'>
                <div className='flex items-center gap-5'>
                  <div className='rounded-xl min-w-14 size-14 flex items-center justify-center bg-[#DDF6E8]'>
                    <img src='/assets/icons/invoice.svg' alt='icon' />
                  </div>
                  <div>
                    <p className='font-medium line-clamp-1'>Invoice</p>
                    <p className='font-semibold text-2xl mt-1'>18</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-col justify-between gap-3'>
              <div className='border rounded-xl bg-White p-4'>
                <div className='flex items-center gap-5'>
                  <div className='rounded-xl min-w-14 size-14 flex items-center justify-center bg-[#cdd8ec]'>
                    <img src="/assets/icons/files-secondary.svg" alt="icon" />
                  </div>
                  <div>
                    <p className='font-medium line-clamp-1'>Files</p>
                    <p className='font-semibold text-2xl mt-1'>14</p>
                  </div>
                </div>
              </div>
              <div className='border rounded-xl bg-White'>
                <div className='p-4'>
                  <p className='font-medium line-clamp-1'>Signature</p>
                  <p className='font-semibold text-2xl mt-1'>27</p>
                </div>
                <AreaChart data={signatureData} colors={['#03C3EC']} height={210} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard