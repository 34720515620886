import React, { useEffect, useState } from 'react';
import SkeletonTable from '../../components/table/SkeletonTable';
import { Input, Switch } from 'antd';
import View from '../../assets/View';
import columns from '../../columns/team/column';

const Team = () => {
    const [data, setData] = useState([]);

    const tableData = [
        { name: 'John Doe', email: 'john.doe@example.com', password: 'Pass1234', },
        { name: 'Jane Smith', email: 'jane.smith@example.com', password: 'SecurePass567', },
        { name: 'Alex Johnson', email: 'alex.johnson@example.com', password: 'MyPassword789', },
    ];

    useEffect(() => {
        setData(tableData.map((item) => {
            return {
                ...item,
                name: <p className='hover:text-primary hover:underline'>{item.name}</p>,
                status: <Switch checkedChildren='On' unCheckedChildren='Off' />,
                action: <button> <View /> </button>
            }
        }));
    }, []);

    return (
        <div className='border rounded-lg mt-5 bg-White'>
            <div className='py-5 px-4 flex items-center justify-between'>
                <p className='font-semibold text-xl'>Team</p>
                <Input size="large" placeholder="Search here..." className='xl:w-80' prefix={
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#B3B3B3" class="size-6">
                        <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z" clip-rule="evenodd" />
                    </svg>}
                />
            </div>
            <div className='mt-5'>
                <SkeletonTable columns={columns} data={data} />
            </div>
        </div>
    )
}

export default Team