const defaultRender = (value) => (value ? value : '--');

const columns = [
    {
        title: 'File',
        dataIndex: 'file',
        render: defaultRender,
    },
    {
        title: '',
        dataIndex: 'action',
        render: defaultRender,
    },
];

export default columns;